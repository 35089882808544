import axios from 'axios';
import qs from 'qs';
import baseURL from '../utils/baseUrl';
import { store } from '../index';
import { CLEAR_USERINFO } from '../constants/userInfo';


axios.defaults.baseURL = baseURL();
axios.defaults.timeout = 30000;
/**
 * 请求拦截器
 */

axios.interceptors.request.use((config: any) => { // 在此处统一配置公共参数
    const power: string | null = localStorage.getItem('tobacco-user');
    if (power && JSON.parse(power)) {
        config.headers.Authorization = JSON.parse(power).token;
    }
    if (config.data && config.data.type === 'upload') {
        config.data = config.data.data;
    } else {
        config.data = qs.stringify(config.data);
    }
    
    return config;

}, (error: any) => Promise.reject(error));


/**
 * 响应拦截器
 */

axios.interceptors.response.use((response: any) => {
    const res = response.data;
    switch (res.code) {
        case '100000':
            break;
        case 400001:    // token过期 或者签名不正确
            store.dispatch({type: CLEAR_USERINFO});
            // store.dispatch('loginOut');
            // store.dispatch('clearRouter');
            break;
        default:
            break;
    }
    return response.data;

}, (error: any) => Promise.reject(error));

export default axios;
