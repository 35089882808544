import loadable from 'react-loadable';
import Loading from '../components/Loading';
import { RoutesType } from '../interface';
function lazyComponent(component: () => Promise<any>) {
    return loadable({
        loader: component,
        loading: Loading,
    })
}

export const staticRoutes: RoutesType[] = [
    {
        name: '登录',
        path: '/login',
        component: lazyComponent(() => import('../container/Login'))
    },
];

export const asyncRoutes: RoutesType[] = [
    {
        name: '首页',
        path: '/index',
        icon: 'iconshouye',
        // component: lazyComponent(() => import('../container/Index')),
    },
    {
        name: '商品管理',
        path: '/goods',
        icon: 'iconshangpin',
        power: 'goods',
        children: [
            {
                name: '烟草商品列表',
                path: '/goods/list',
                power: 'yc_goods',
                component: lazyComponent(() => import('../container/Goods')),
                children: [
                    {
                        name: '新增商品',
                        path: '/goods/add',
                        meta: {
                            parent: {
                                name: '烟草商品列表',
                                path: '/goods/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Goods/add'))
                    },
                    {
                        name: '烟草商品详情',
                        path: '/goods/detail/:id',
                        meta: {
                            parent: {
                                name: '烟草商品列表',
                                path: '/goods/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Goods/detail'))
                    },
                    {
                        name: '编辑商品',
                        path: '/goods/edit/:id',
                        meta: {
                            parent: {
                                name: '烟草商品列表',
                                path: '/goods/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Goods/edit'))
                    },
                    {
                        name: '导入商品',
                        path: '/goods/import',
                        meta: {
                            parent: {
                                name: '烟草商品列表',
                                path: '/goods/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Goods/import'))
                    },
                ]
            },
            {
                name: '非烟商品列表',
                path: '/goods/ungoodslist',
                power: 'non_yc_goods',
                component: lazyComponent(() => import('../container/UnGoods')),
                children: [
                    {
                        name: '新增商品',
                        path: '/goods/ungoodslistAdd',
                        meta: {
                            parent: {
                                name: '非烟商品列表',
                                path: '/goods/ungoodslist',
                            }
                        },
                        component: lazyComponent(() => import('../container/UnGoods/add'))
                    },
                    {
                        name: '编辑商品',
                        path: '/goods/ungoodslistEdit/:id',
                        meta: {
                            parent: {
                                name: '非烟商品列表',
                                path: '/goods/ungoodslist',
                            }
                        },
                        component: lazyComponent(() => import('../container/UnGoods/edit'))
                    },
                    {
                        name: '非烟商品详情',
                        path: '/goods/ungoodslistDetail/:id',
                        meta: {
                            parent: {
                                name: '非烟商品列表',
                                path: '/goods/ungoodslist',
                            }
                        },
                        component: lazyComponent(() => import('../container/UnGoods/detail'))
                    },
                    {
                        name: '导入商品',
                        path: '/goods/ungoodslistImport',
                        meta: {
                            parent: {
                                name: '非烟商品列表',
                                path: '/goods/ungoodslist',
                            }
                        },
                        component: lazyComponent(() => import('../container/UnGoods/import'))
                    },
                ]
            }
        ]
    },
    {
        name: '商户管理',
        path: '/business',
        icon: 'iconshanghu',
        power: 'business',
        children: [
            {
                name: '商户管理',
                path: '/business/list',
                power: 'business_list',
                component: lazyComponent(() => import('../container/Business')),
                children: [
                    {
                        name: '新增商户',
                        path: '/business/add',
                        meta: {
                            parent: {
                                name: '商户管理',
                                path: '/business/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Business/add')),
                    },
                    {
                        name: '商户详情',
                        path: '/business/detail/:id',
                        meta: {
                            parent: {
                                name: '商户管理',
                                path: '/business/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Business/detail')),
                    },
                    {
                        name: '批量添加',
                        path: '/business/batchImport',
                        meta: {
                            parent: {
                                name: '商户管理',
                                path: '/business/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Business/batchImport'))
                    },
                    {
                        name: '批量编辑',
                        path: '/business/batchEdit',
                        meta: {
                            parent: {
                                name: '商户管理',
                                path: '/business/list'
                            }
                        },
                        component: lazyComponent(() => import('../container/Business/batchEdit'))
                    },
                    {
                        name: '编辑商户',
                        path: '/business/edit/:id',
                        meta: {
                            parent: {
                                name: '商户管理',
                                path: '/business/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Business/edit')),
                    },
                ]
            }
        ]
    },
    {
        name: '用户管理',
        path: '/role',
        icon: 'iconyonghu',
        power: 'user',
        children: [
            {
                name: '用户管理',
                path: '/role/list',
                component: lazyComponent(() => import('../container/Role')),
                power: 'user_list',
                children: [
                    {
                        name: '新增账号',
                        path: '/role/add',
                        meta: {
                            parent: {
                                name: '用户管理',
                                path: '/role/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Role/add')),
                    },
                    {
                        name: '账号详情',
                        path: '/role/detail/:id',
                        meta: {
                            parent: {
                                name: '用户管理',
                                path: '/role/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Role/detail'))
                    },
                    {
                        name: '编辑账号',
                        path: '/role/edit/:id/:flag?',
                        meta: {
                            parent: {
                                name: '用户管理',
                                path: '/role/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Role/edit')),
                    },
                    {
                        name: '权限管理',
                        path: '/role/power/:userId',
                        meta: {
                            parent: {
                                name: '用户管理',
                                path: '/role/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Role/power')),
                    },
                    {
                        name: '导入用户',
                        path: '/role/import',
                        meta: {
                            parent: {
                                name: '用户管理',
                                path: '/role/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Role/import'))
                    },
                ]
            },
            {
                name: '角色权限管理',
                path: '/role/authority',
                power: 'role_auth',
                component: lazyComponent(() => import('../container/RoleAuthority')),
            },
            {
                name: '体验账户列表',
                path: '/role/UserExperience',
                power: 'experience_user_list',
                component: lazyComponent(() => import('../container/UserExperience')),
                children: [
                    {
                        name: '编辑体验账户',
                        path: '/role/UserExperienceEdit/:user_id',
                        meta: {
                            parent: {
                                name: '体验账户列表',
                                path: '/role/UserExperience',
                            }
                        },
                        component: lazyComponent(() => import('../container/UserExperience/edit')),
                    },
                    {
                        name: '详情体验账户',
                        path: '/role/UserExperienceDetail/:user_id',
                        meta: {
                            parent: {
                                name: '体验账户列表',
                                path: '/role/UserExperience',
                            }
                        },
                        component: lazyComponent(() => import('../container/UserExperience/detail')),
                    },
                ]
            },
        ]
    },
    {
        name: '内容管理',
        path: '/content',
        icon: 'iconneirong',
        power: 'content',
        children: [
            {
                name: '轮播图',
                path: '/content/slider/list',
                power: 'banner_list',
                component: lazyComponent(() => import('../container/Slider')),
                children: [
                    {
                        name: '新增轮播图',
                        path: '/content/slider/add',
                        meta: {
                            parent: {
                                name: '轮播图',
                                path: '/content/slider/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Slider/add')),
                    },
                    {
                        name: '编辑轮播图',
                        path: '/content/slider/edit/:id',
                        meta: {
                            parent: {
                                name: '轮播图',
                                path: '/content/slider/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Slider/edit')),
                    }
                ]
            },
            {
                name: '公告管理',
                path: '/content/notice/list',
                power: 'announcement',
                component: lazyComponent(() => import('../container/Notice')),
                children: [
                    {
                        name: '新增公告',
                        path: '/content/notice/add',
                        meta: {
                            parent: {
                                name: '公告管理',
                                path: '/content/notice/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Notice/add')),
                    },
                    {
                        name: '公告详情',
                        path: '/content/notice/detail/:id',
                        meta: {
                            parent: {
                                name: '公告管理',
                                path: '/content/notice/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Notice/detail'))
                    },
                    {
                        name: '编辑公告',
                        path: '/content/notice/edit/:id',
                        meta: {
                            parent: {
                                name: '公告管理',
                                path: '/content/notice/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Notice/edit')),
                    },
                ]
            },
            {
                name: '文章列表',
                path: '/content/article/list',
                power: 'acticle_list',
                component: lazyComponent(() => import('../container/Article')),
            },
            {
                name: '意见回复',
                path: '/content/opinion/list',
                power: 'suggestion',
                component: lazyComponent(() => import('../container/Opinion')),
                children: [
                    {
                        name: '反馈详情',
                        path: '/content/opinion/detail',
                        meta: {
                            parent: {
                                name: '意见回复',
                                path: '/content/opinion/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Opinion/detail')),
                    }
                ]
            },
            {
                name: '价格自律',
                path: '/content/pricediscipline',
                power: 'price_announce',
                component: lazyComponent(() => import('../container/PriceDiscipline')),
            }
        ]
    },
    {
        name: '系统管理',
        path: '/system',
        icon: 'iconxitong',
        power: 'system',
        children: [
            {
                name: '模板账户管理',
                path: '/system/TemplateAccount',
                power: 'template_user',
                component: lazyComponent(() => import('../container/TemplateAccount')),
            },
            {
                name: '偏移管理',
                path: '/system/deviation/list',
                power: 'machine_offset',
                component: lazyComponent(() => import('../container/Deviation')),
            },
            {
                name: '烟草模板管理',
                path: '/system/template/list',
                power: 'template_list',
                component: lazyComponent(() => import('../container/Template')),
                children: [
                    {
                        name: '编辑模板类型',
                        path: '/system/template/edittype',
                        meta: {
                            parent: {
                                name: '烟草模板管理',
                                path: '/system/template/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Template/editType')),
                    },
                    {
                        name: '编辑模板',
                        path: '/system/template/edit/:id',
                        meta: {
                            parent: {
                                name: '烟草模板管理',
                                path: '/system/template/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Template/editTemplate')),
                    }
                ]
            },
            {
                name: '非烟模板管理',
                path: '/system/nonTemplate/list',
                power: 'non_goods_template_list',
                component: lazyComponent(() => import('../container/NonTemplate')),
                children: [
                    {
                        name: '编辑模板类型',
                        path: '/system/nonTemplate/edittype',
                        meta: {
                            parent: {
                                name: '非烟模板管理',
                                path: '/system/nonTemplate/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/NonTemplate/addTemplate')),
                    },
                    {
                        name: '编辑模板',
                        path: '/system/nonTemplate/edit/:id',
                        meta: {
                            parent: {
                                name: '非烟模板管理',
                                path: '/system/nonTemplate/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/NonTemplate/editTemplate')),
                    }
                ]
            },
            {
                name: '操作日志',
                path: '/system/log/list',
                power: 'operate_log',
                component: lazyComponent(() => import('../container/Log')),
            },
            {
                name: '烟草字段管理',
                path: '/system/filed/list',
                power: 'yc_field',
                component: lazyComponent(() => import('../container/Filed')),
                children: [
                    {
                        name: '新增烟草字段',
                        path: '/filed/add',
                        meta: {
                            parent: {
                                name: '烟草字段管理',
                                path: '/system/filed/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Filed/add')),
                    },
                    {
                        name: '编辑烟草字段',
                        path: '/filed/edit/:id',
                        meta: {
                            parent: {
                                name: '烟草字段管理',
                                path: '/system/filed/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Filed/edit')),
                    },
                ]
            },
            {
                name: '非烟字段管理',
                path: '/system/un_filed/list',
                power: 'non_yc_field',
                component: lazyComponent(() => import('../container/UnFiled')),
                children: [
                    {
                        name: '新增非烟烟草字段',
                        path: '/un_filed/add',
                        meta: {
                            parent: {
                                name: '非烟字段管理',
                                path: '/system/un_filed/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/UnFiled/add')),
                    },
                    {
                        name: '编辑非烟烟草字段',
                        path: '/un_filed/edit/:id',
                        meta: {
                            parent: {
                                name: '非烟字段管理',
                                path: '/system/un_filed/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/UnFiled/edit')),
                    },
                ]
            },
            {
                name: '版本管理',
                path: '/system/version/list',
                power: 'app_version',
                component: lazyComponent(() => import('../container/Version')),
                children: [
                    {
                        name: '新增非烟烟草字段',
                        path: '/un_filed/add',
                        meta: {
                            parent: {
                                name: '非烟字段管理',
                                path: '/system/un_filed/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/UnFiled/add')),
                    },
                    {
                        name: '编辑非烟烟草字段',
                        path: '/un_filed/edit/:id',
                        meta: {
                            parent: {
                                name: '非烟字段管理',
                                path: '/system/un_filed/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/UnFiled/edit')),
                    },
                ]
            },
            {
                name: '固件升级',
                path: '/system/firmware/list',
                power: 'firmware_update',
                component: lazyComponent(() => import('../container/Firmware/list')),
            },
            {
                name: '系统配置',
                path: '/system/getConfig/list',
                power: 'system_config',
                component: lazyComponent(() => import('../container/SystemConfig')),
            },
        ]
    },
    {
        name: '统计分析',
        path: '/statistical',
        icon: 'iconxitong',
        power: 'statistics',
        children: [
            {
                name: '烟草打印统计',
                path: '/statistical/analysis/list',
                power: 'goods_statistics',
                component: lazyComponent(() => import('../container/Statistical')),
            },
            {
                name: '商户报表',
                path: '/statistical/MerchantStatistical/list',
                power: 'merchant_report',
                component: lazyComponent(() => import('../container/MerchantStatistical')),
            },
            {
                name: '区管报表',
                path: '/statistical/statistics/list',
                power: 'regional_report',
                component: lazyComponent(() => import('../container/newStatistical')),
                children: [
                    {
                        name: '个人明细',
                        path: '/statistical/MerchantStatistical/detail/:user_id/:time',
                        meta: {
                            parent: {
                                name: '区管报表',
                                path: '/statistical/statistics/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/newStatistical/detail')),
                    }
                ]
            }
        ]
    },
    {
        name: '单据管理',
        path: '/billManage',
        icon: 'iconxitong',
        power: 'bill',
        children: [
            {
                name: '价签申领单',
                path: '/billManage/labelbill/list',
                power: 'label_bill',
                component: lazyComponent(() => import('../container/Labelbill')),
                children: [
                    {
                        name: '价签申领单详情',
                        path: '/billManage/labelbill/detail/:id',
                        meta: {
                            parent: {
                                name: '价签申领单',
                                path: '/billManage/labelbill/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/Labelbill/detail')),
                    },
                ]
            },
            {
                name: '商户拜访单',
                path: '/billManage/visitTask/list',
                power: 'visit_bill',
                component: lazyComponent(() => import('../container/VisitTask')),
                children: [
                    {
                        name: '商户拜访单详情',
                        path: '/billManage/visitTask/detail/:id',
                        meta: {
                            parent: {
                                name: '商户拜访单',
                                path: '/billManage/visitTask/list',
                            }
                        },
                        component: lazyComponent(() => import('../container/VisitTask/detail')),
                    },
                ]
            }
        ]
    },
];