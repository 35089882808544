import setBaseUrl from '../utils/baseUrl';
import axios from './apiConfig';

/************************ 公共接口 *********************/
// 登录
export const manageLogin = (payload: object) => {
    return axios.post('manage/login', payload)
}
// 获取菜单
export const manageMenu = () => {
    return axios.get('/manage/menu');
}
// 获取APP菜单
export const manageAppMenu = () => {
    return axios.get('/manage/appMenu');
}
// 获取非烟商品菜单
export const manageGetNonYcGoodsMenu = () => {
    return axios.get('/manage/getNonYcGoodsMenu');
}
export const manageGetNonYcGoodsMenuApp = () => {
    return axios.get('/manage/getNonYcGoodsMenuApp');
}
// 图片上传
export const manageUploadImage = '/manage/upload/image'
// 修改密码
export const manageUpdatepwd = (payload: object) => {
    return axios.post('/manage/updatepwd', payload);
}
//
export const manageTemplateTypeLists = () => {
    return axios.get('/manage/templateType/lists')
}














// 获取角色列表 
export const manageRoleLists = (payload: object) => {
    return axios.get('/manage/role/lists', { params: payload });
}

// 获取角色菜单权限
export const manageRoleGetRoleAuth = (payload: object) => {
    return axios.get('/manage/role/getRoleAuth', { params: payload });
}
// 获取角色菜单APP权限
export const manageRoleGetRoleAuthApp = (payload: object) => {
    return axios.get('/manage/role/getRoleAuthApp', { params: payload })
}
// 保存角色菜单权限
export const manageRoleSetRoleAuth = (payload: object) => {
    return axios.post('/manage/role/setRoleAuth', payload);
}
// 保存角色APP菜单权限
export const manageRoleSetRoleAuthApp = (payload: object) => {
    return axios.post('/manage/role/setRoleAuthApp', payload);
}
// 获取地区
export const manageUserDistrict = (payload: object) => {
    return axios.get('/manage/user/district', { params: payload });
}
// 图片上传
export const uploaImg = `${setBaseUrl()}/manage/upload/image`;
// 文件上传
export const uploaExcel = `${setBaseUrl()}/manage/upload/excel`;


/*   商品管理    */
//  商品列表
export const goodsLists = '/manage/goods/getList';
// 获取下属区级别商品信息
export const getSubGoodsInfo = (payload: object) => {
    return axios.get('/manage/goods/getSubGoodsInfo', { params: payload });
}
//验证商品条码(盒包装)是否存在
export const getCheckBarCodeExist = (payload: object) => {
    return axios.get('/manage/goods/checkBarCodeExist', { params: payload });
}
//验证固件升级
export const checkIsExists = (payload: object) => {
    return axios.get('/manage/firmwareUpdate/checkIsExists', { params: payload });
}
//验证商品条码(条包装)是否存在
export const getCheckItemBarCodeExist = (payload: object) => {
    return axios.get('/manage/goods/checkItemBarCodeExist', { params: payload });
}
// 添加商品字段
export const getGoodsAttr = (payload: object) => {
    return axios.get('/manage/getMyGoodsAttr', { params: payload });
}
// 导入商品字段
export const getFieldList = (payload: object) => {
    return axios.get('/manage/goodsImport/getFieldList', { params: payload });
}
// 下载模板
export const downloadSampleExcel = (payload: object) => {
    return axios.get('/manage/goodsImport/downloadSampleExcel', { params: payload });
}
// 导入商品
export const importExcel = (payload: object) => {
    return axios.post('/manage/goodsImport/importExcel', payload);
}
// 导出商品
export const exportExcel = (payload: object) => {
    return axios.get('/manage/goods/export', { params: payload });
}
// 同步商品
export const goodsSyncPort = (payload: object) => {
    return axios.post('/manage/goods/sync', payload);
}
//  添加商品
export const goodsCreate = (payload: object) => {
    return axios.post('/manage/goods/save', payload);
}
// 删除商品
export const goodsDelete = '/manage/goods/delete';
// 烟草商品详情获取
export const goodsGetOne = (payload: object) => {
    return axios.get('/manage/goods/getOne', { params: payload });
}
// 编辑商品
export const goodsUpdate = (payload: object) => {
    return axios.post('/manage/goods/update', payload);
}
// 品牌查询
export const getBrand = (payload: object) => {
    return axios.get('/manage/goods/getBrand', { params: payload });
}

/********************* 非烟商品 ********************/
//非烟字段获取
//  非烟商品列表
export const nonGoodsLists = '/manage/nonGoods/getList';
export const nonGoodsCreate = (payload: object) => {
    return axios.post('/manage/nonGoods/save', payload);
}
//校验非烟商品已存在
export const getCheckBarCode = (payload: object) => {
    return axios.get('/manage/nonGoods/checkBarCode', { params: payload })
}
//非烟商品详情
export const nonGoodsGetOne = (payload?: object) => {
    return axios.get('/manage/nonGoods/getOne', { params: payload })
}
//编辑商品
export const nonGoodsUpdate = (payload: object) => {
    return axios.post('/manage/nonGoods/update', payload)
}
//非烟字段获取
export const getNonGoodsAttr = (payload?: object) => {
    return axios.get('/manage/getMyNonGoodsAttr', { params: payload })
}
// 非烟商品下载模板
export const nonGoodsDownloadSampleExcel = (payload: object) => {
    return axios.get('/manage/nonGoods/downloadSampleExcel', { params: payload });
}
// 删除非烟商品
export const nonGoodsDeleteApi = '/manage/nonGoods/delete';
// 导出非烟商品
export const nonGoodsExportExcel = (payload: object) => {
    return axios.get('/manage/nonGoods/export', { params: payload });
}
// 查询异步导出非烟商品结果
export const nonGoodsGetExportResult = (payload: object) => {
    return axios.get('/manage/nonGoods/getExportResult', { params: payload });
}
// 导入非烟商品
export const nonGoodsImportExcel = (payload: object) => {
    return axios.post('/manage/nonGoods/importExcel', payload);
}
// 查询异步导入非烟商品结果
export const nonGoodsGetImportResult = (payload: object) => {
    return axios.get('/manage/nonGoods/getImportResult', payload);
}
/********************* 商户管理 **********************/
// 商户列表
export const manageBusinessLists = '/manage/business/lists';
// 导出商户店铺信息
export const manageBusinessExport = (payload: object) => {
    return axios.get('/manage/business/exportBusiness', { params: payload });
}
export const exportMerchantReport = (payload: object) => {
    return axios.get('/manage/statistics/exportMerchantReport', { params: payload });
}
export const exportDetailed = (payload: object) => {
    return axios.get('/manage/statistics/exportDetailed', { params: payload });
}
export const exportRegionalReport = (payload: object) => {
    return axios.get('/manage/statistics/exportRegionalReport', { params: payload });
}
// 获取烟草商户角色列表
export const manageBusinessRoleList = (payload: object) => {
    return axios.get('/manage/business/getBusinessRole', { params: payload });
}
// 获取烟草客户经理列表
export const manageGetManagerUserList = (payload: object) => {
    return axios.get('/manage/business/getManagerUser', { params: payload });
}
// 获取经营信息字段
export const getBusinessInfoField = (payload: object) => {
    return axios.get('/manage/business/getBusinessInfoField', { params: payload });
}
// 新增商户
export const manageAddBusiness = (payload: object) => {
    return axios.post('/manage/business/create', payload);
}
// 获取商户详情
export const getBusinessDetail = (payload: object) => {
    return axios.get('/manage/business/detail', { params: payload });
}
//批量编辑商户
export const setBatchUpdate = (payload: object) => {
    return axios.post('/manage/business/batchUpdate', payload)
}
// 更新商户
export const manageEditBusiness = (payload: object) => {
    return axios.post('/manage/business/update', payload);
}
// 删除商户
export const manageDeleteBusiness = '/manage/business/delete';
// 批量添加模板下载
export const businessDownloadSampleExcel = (payload: object) => {
    return axios.get('/manage/business/downloadSampleExcel', { params: payload });
}
// 非烟模版批量分配用户模版下载
export const templateUserDownloadSampleExcel = (payload: object) => {
    return axios.get('/manage/templateUser/downloadSampleExcel', { params: payload });
}
//删除烟草地区模版
export const templateDistrictDelete = (payload: object) => {
    return axios.post('/manage/templateDistrict/delete', payload);
}
//非烟模版批量分配用户
export const templateUserImportExcel = (payload: object) => {
    return axios.post('/manage/templateUser/importExcel', payload);
}
//获取无限制店铺地址地区
export const getShopAddress = (payload: object) => {
    return axios.get('/manage/district/getList', { params: payload });
}
//导入商户
export const businessImportExcel = (payload: object) => {
    return axios.post('/manage/business/importExcel', payload);
}
//商户启用禁用
export const businessSetStatus = (payload: object) => {
    return axios.post('/manage/business/setStatus', payload);
}
/********************* 用户管理 **********************/
export const manageUserLists = '/manage/user/lists';
export const manageRoleGetRolesByDistrict = (payload: object) => {
    return axios.get('/manage/role/getRolesByDistrict', { params: payload });
}
export const getRoleByDistrict = (payload: object) => {
    return axios.get('/manage/role/getRoleByDistrict', { params: payload });
}
export const manageUserGenerateAccount = (payload: object) => {
    return axios.post('/manage/user/generateAccount', payload);
}
// 获取用户菜单权限
export const manageUserGetUserAuth = (payload: object) => {
    return axios.get('/manage/user/getUserAuth', { params: payload });
}
// 分配用户菜单权限
export const manageUserSetUserAuth = (payload: object) => {
    return axios.post('/manage/user/setUserAuth', payload);
}
// 获取用户菜单APP权限
export const manageUserGetUserAuthApp = (payload: object) => {
    return axios.get('/manage/user/getUserAuthApp', { params: payload });
}
// 分配用户APP菜单权限
export const manageUserSetUserAuthApp = (payload: object) => {
    return axios.post('/manage/user/setUserAuthApp', payload);
}
// 创建用户
export const manageUserCreate = (payload: object) => {
    return axios.post('/manage/user/create', payload);
}
// 删除用户
export const manageUserDelete = '/manage/user/delete'
// 开启/禁用用户
export const manageUserSetStatus = (payload: object) => {
    return axios.post('/manage/user/setStatus', payload);
}
// 导出用户
export const manageUserExport = (payload: object) => {
    return axios.get('/manage/business/exportUser', { params: payload });
}
export const manageUserExportSelect = (payload: object) => {
    return axios.get('/manage/user/export', { params: payload });
}
// 校验用户信息是否存在
export const manageUserCheckUserExist = (payload: object) => {
    return axios.post('/manage/user/checkUserExist', payload);
}
// 获取用户详情
export const manageUserDetail = (payload: object) => {
    return axios.get('/manage/user/detail', { params: payload });
}
// 修改用户
export const manageUserUpdate = (payload: object) => {
    return axios.post('/manage/user/update', payload);
}
// 获取角色组权限  ---  tree
export const manageRoleGroupGetRoleGroupAuth = (payload: object) => {
    return axios.get('/manage/roleGroup/getRoleGroupAuth', { params: payload });
}
// 获取角色组APP权限 --- tree
export const manageRoleGroupGetRoleGroupAuthApp = (payload: object) => {
    return axios.get('manage/roleGroup/getRoleGroupAuthApp', { params: payload });
}
// 获取角色组权限  ---  id数组
export const manageRoleGroupGetRoleGroupAuthIds = (payload: object) => {
    return axios.get('/manage/roleGroup/getRoleGroupAuthIds', { params: payload });
}
// 获取角色组APP权限 --- id数组
export const manageRoleGroupGetRoleGroupAuthIdsApp = (payload: object) => {
    return axios.get('manage/roleGroup/getRoleGroupAuthIdsApp', { params: payload });
}
// 分配角色组权限
export const manageRoleGroupSetRoleGroupAuth = (payload: object) => {
    return axios.post('/manage/roleGroup/setRoleGroupAuth', payload);
}
// 分配角色组APP权限
export const manageRoleGroupSetRoleGroupAuthApp = (payload: object) => {
    return axios.post('manage/roleGroup/setRoleGroupAuthApp', payload);
}
// 创建角色
export const manageRoleCreate = (payload: object) => {
    return axios.post('/manage/role/create', payload);
}
// 角色重命名
export const manageRoleIpdate = (payload: object) => {
    return axios.post('/manage/role/update', payload);
}
// 删除角色
export const manageRoleDelete = (payload: object) => {
    return axios.post('/manage/role/delete', payload);
}
//通过用户ID获取商户详情
export const userIdGetGoodsId = (payload: object) => {
    return axios.get('/manage/business/getDetailByUserId', { params: payload })
}
//获取导入用户模版样例
export const getSampleExcel = (payload: object) => {
    return axios.get('manage/user/downloadSampleExcel', { params: payload })
}
//导入用户
export const importUserExcel = (payload: object) => {
    return axios.post('manage/user/importExcel', payload);
}
//体验用户列表
export const getUserExperience = 'manage/userExperience/getList'
//体验用户批量导出
export const exportUserExperience = (payload: object) => {
    return axios.get('/manage/userExperience/export', { params: payload })
}
//删除体验用户
export const deleteUserExperience = '/manage/userExperience/delete'
//编辑体验账户
export const updateUserExperience = (payload: object) => {
    return axios.post('/manage/userExperience/update', payload);
}
/****************************内容管理 ***********************/
// 轮播图列表
export const manageBannerGetList = '/manage/banner/getList';
// 新增轮播图
export const manageBannerSave = (payload: object) => {
    return axios.post('/manage/banner/save', payload);
}
// 获取轮播图详情
export const manageBannerGetOne = (payload: object) => {
    return axios.get('/manage/banner/getOne', { params: payload });
}
// 轮播图更新
export const manageBannerUpdate = (payload: object) => {
    return axios.post('/manage/banner/update', payload);
}
// 轮播图删除
export const manageBannerDelete = '/manage/banner/delete';
// 意见反馈列表
export const manageFeedbackGetList = '/manage/feedback/getList';
// 回复意见反馈
export const manageFeedbackSaveReply = (payload: object) => {
    return axios.post('/manage/feedback/saveReply', payload);
}
//撤销回复
export const deleteReply = (payload: object) => {
    return axios.post('/manage/feedback/deleteReply', payload);
}
// 删除意见反馈
export const manageFeedbackDelete = '/manage/feedback/delete'
// 文章列表
export const manageArticleGetList = '/manage/article/getList';
// 新增文章
export const manageArticleSave = (payload: object) => {
    return axios.post('/manage/article/save', payload);
}
// 更新文章
export const manageArticleUpdate = (payload: object) => {
    return axios.post('/manage/article/update', payload);
}
// 删除文章
export const manageArticleDelete = '/manage/article/delete';
// 价格自律保存
export const manageGoodspriceannounceSave = (payload: object) => {
    return axios.post('/manage/goodspriceannounce/save', payload);
}
//公告列表
export const getAnnouncement = '/manage/announcement/getList'
//新增公告
export const addAnnouncement = (payload: object) => {
    return axios.post('/manage/announcement/save', payload);
}
//编辑公告
export const updateAnnouncement = (payload: object) => {
    return axios.post('/manage/announcement/update', payload);
}
//发布公告
export const toPublish = (payload: object) => {
    return axios.post('/manage/announcement/publish', payload);
}
//删除公告
export const deleteAnnouncement = '/manage/announcement/delete'
/************************* 系统管理 **********************/
// 标签模板列表
export const manageTemplateLists = '/manage/template/lists';
//同步模版到地区
export const templateSync = (payload: object) => {
    return axios.post('/manage/template/sync', payload);
}
// 标签模板切换状态
export const manageTemplateSetStatus = (payload: object) => {
    return axios.post('/manage/template/setStatus', payload);
}
// 标签模板切换状态
export const userRfidSetStatus = (payload: object) => {
    return axios.post('/manage/user/setRfidStatus', payload);
}
// 删除标签模板
export const manageTemplateDelete = '/manage/template/delete';
// 设置模板类型
export const manageTemplateTypeSetting = (payload: object) => {
    return axios.post('/manage/templateType/setting', payload);
}
//Gary新增接口
// 获取模板详情
export const getTempalteDetail = (payload: object) => {
    return axios.get('/manage/template/detail', { params: payload });
}
//编辑模板详情
export const updateTem = (payload: object) => {
    return axios.post('/manage/template/update', payload);
}
// 日志列表
export const logLists = '/manage/operateLog/lists';
export const getMerchantReport = '/manage/statistics/getMerchantReport';
export const getRegionalReport = '/manage/statistics/getRegionalReport';
export const getMerchantDetail = '/manage/statistics/getMerchantDetail';
export const logListsAxios = (payload: object) => {
    return axios.get('/manage/operateLog/lists', { params: payload });
}
// 字段列表
export const filedLists = '/manage/goodsField/getList';
// 删除字段
export const manageDeleteFiled = '/manage/goodsField/delete';
//添加字段
export const addFiled = (payload: object) => {
    return axios.post('/manage/goodsField/save', payload);
}
//更新字段
export const updateFiled = (payload: object) => {
    return axios.post('/manage/goodsField/update', payload);
}
// 获取字段详情
export const getFiledDetail = (payload: object) => {
    return axios.get('/manage/goodsField/getFieldConfigDetail', { params: payload });
}
//非烟字段获取
export const getNonGoodsField = (payload?: object) => {
    return axios.get('/manage/getMyNonGoodsField', payload);
}
//非烟字段列表
export const nonFiledLists = '/manage/nonGoodsField/getList';
//新增非烟字段
export const addNonGoodsField = (payload: object) => {
    return axios.post('/manage/nonGoodsField/save', payload);
}
//编辑非烟字段
export const editNonGoodsField = (payload: object) => {
    return axios.post('/manage/nonGoodsField/update', payload);
}
//非烟校验绑定用户
export const checkUserNonGoodsField = (payload: object) => {
    return axios.get('/manage/nonGoodsField/checkUser', { params: payload });
}
//非烟字段编辑详情
export const detailNonGoodsField = (payload?: object) => {
    return axios.get('/manage/nonGoodsField/getOne', { params: payload });
}
//是否屏蔽烟草
export const isShieldTobacco = (payload: object) => {
    return axios.post('/manage/nonGoodsField/isShieldTobacco', payload);
}
//删除非烟字段方案
export const deleteNonGoodsField = '/manage/nonGoodsField/delete'
//该地区是否已有新增字段
export const checkConfigIsExist = (payload?: object) => {
    return axios.get('/manage/goodsField/checkConfigIsExist', { params: payload });
}
// 机型偏移列表
export const getDeviationList = "/manage/machineOffset/getList"
// 机型偏移新增
export const addDeviation = (payload: object) => {
    return axios.post('/manage/machineOffset/save', payload);
}
// 机型偏移编辑
export const editDeviation = (payload: object) => {
    return axios.post('/manage/machineOffset/update', payload);
}
// 机型偏移删除
export const deleteDeviation = "/manage/machineOffset/delete";
//非烟模版列表
export const getNonGoodsList = "/manage/template/getNonGoodsList"
//编辑烟草模版类型
export const updateGood = (payload: object) => {
    return axios.post('/manage/templateType/updateGoods', payload);
}
//编辑非烟模版类型
export const updateNonGoods = (payload: object) => {
    return axios.post('/manage/templateType/updateNonGoods', payload);
}
//获取非烟固定字段
export const getNonGoodsFixedField = (payload?: object) => {
    return axios.get('/manage/getNonGoodsFixedField', { params: payload });
}
//校验绑定模版用户
export const nonGoodscheckUser = (payload?: object) => {
    return axios.get('/manage/template/checkUser', { params: payload });
}
//非烟模板详情
export const getNonGoodsOne = (payload?: object) => {
    return axios.get('/manage/template/getNonGoodsOne', { params: payload });
}
//获取烟草固定字段
export const getGoodsFixedField = (payload?: object) => {
    return axios.get('/manage/getGoodsFixedField', { params: payload });
}
//系统配置获取-V1.2.3
export const getSystemConfig = (payload?: object) => {
    return axios.get('/manage/system/getConfig', { params: payload });
}
//配置参数修改-V1.2.3
export const setSystemConfig = (payload: object) => {
    return axios.post('/manage/system/setConfig', payload);
}
/************************* 版本管理 **********************/
//版本列表
export const appVersion = "/manage/appVersion/getList"
//添加版本
export const appVersionAdd = (payload: object) => {
    return axios.post('/manage/appVersion/save', payload);
}
//编辑版本
export const appVersionUpdate = (payload: object) => {
    return axios.post('/manage/appVersion/update', payload);
}
//删除版本
export const appVersionDelete = "/manage/appVersion/delete"
export const firmwareDelete = "/manage/firmwareUpdate/delete"
//添加版本
export const firmwareUpdateCreate = (payload: object) => {
    return axios.post('/manage/firmwareUpdate/create', payload);
}
export const firmwareUpdateUpdate = (payload: object) => {
    return axios.post('/manage/firmwareUpdate/update', payload);
}
/************************* 统计分析 **********************/
export const getGoodsStatistics = '/manage/statistics/getGoodsStatistics';
export const exportGoodsStatistics = (payload?: object) => {
    return axios.get('/manage/statistics/exportGoodsStatistics', { params: payload });
}
/************************* 埋点 **********************/
//（首次）访问web
export const webOpen = (payload: object) => {
    return axios.post('/manage/buryingPoint/open', payload);
}
/************************* 单据管理 **********************/
//价签申领列表-V1.2.3
export const getPriceTagClaimInvoice = '/manage/priceTagClaimInvoice/getList'
//价签申领删除-V1.2.3
export const delPriceTagClaimInvoice = '/manage/priceTagClaimInvoice/del'
//价签申领导出
export const exportPriceTagClaimInvoice = (payload: object) => {
    return axios.post('/manage/priceTagClaimInvoice/export', payload);
}
//价签申领详情-V1.2.3
export const getDetailPriceTagClaimInvoice = (payload?: object) => {
    return axios.get('/manage/priceTagClaimInvoice/getDetail', { params: payload });
}
//商户拜访列表-V1.2.3
export const getVisitTask = '/manage/visitTask/getList'
//商户拜访任务单删除-V1.2.3
export const delVisitTask = '/manage/visitTask/del'
//商户拜访任务导出-V1.2.3
export const exportVisitTask = (payload: object) => {
    return axios.post('/manage/visitTask/export', payload);
}
//商户拜访详情接口-V1.2.3
export const getDetailVisitTask = (payload?: object) => {
    return axios.get('/manage/visitTask/getDetail', { params: payload });
}

/******** 弹窗搜索(商品列表,烟草模板管理,非烟模板管理,非烟字段) **********/
// 获取下级商品列表、搜索
export const getSubGoodsList = '/manage/goods/getSubGoodsList'
// 模版所属地区列表、搜索
export const getBelongDistrictList = '/manage/template/getBelongDistrictList'
// 非烟模版绑定用户列表
export const getNonGoodsBindUserList = '/manage/template/getNonGoodsBindUserList'
// 非烟字段绑定用户列表
export const getBindUserList = '/manage/nonGoodsField/getBindUserList'
//非烟字段绑定用户
export const bindUserNonGoodsField = (payload: object) => {
    return axios.post('/manage/nonGoodsField/bindUser', payload);
}
// 非烟模版绑定用户
export const bindUserTemplate = (payload: object) => {
    return axios.post('/manage/template/bindUser', payload);
}
//删除非烟字段绑定用户
// export const deleteBindUser = '/manage/nonGoodsField/deleteBindUser'
export const deleteBindUser = (payload: object) => {
    return axios.post('/manage/nonGoodsField/deleteBindUser', payload);
}
//删除模版绑定用户  
export const deleteBindUserTemplate = (payload: object) => {
    return axios.post('/manage/template/deleteBindUser', payload);
}

// 模板账户管理--列表
export const templateAccountList = '/manage/Template/userList';
export const getTemplateAccountList = (payload?: object) => {
    return axios.get('/manage/Template/userList', { params: payload });
}
// 模板账户管理--账号验证
export const checkTemplateAccount = (payload?: object) => {
    return axios.get('/manage/Template/checkPhone', { params: payload });
}
// 模板账户管理--添加
export const addTemplateAccount = (payload: object) => {
    return axios.post('/manage/Template/userAdd', payload);
}
// 模板账户管理--删除
export const deleteTemplateAccount = '/manage/Template/userDel';

// 模板账户管理--同步单个模板账号
export const asyncTemplateAccount = (payload: object) => {
    return axios.post('/manage/syncNewTemplate', payload);
}

export const queryIndexStatistics = () => axios.get('/manage/index/getGoodsStatistics');

export const queryInvoiceApproverList = (params: { district_id: number }) => axios.get('/manage/user/getInvoiceApproverSelectList', { params });

export const queryHomeNotificationList = (params: object) => axios.get('/manage/index/getNotificationList', { params });
export const changeHomeNotificationRead = (params: object) => axios.post('/manage/index/setNotificationRead', params);
export const multiRelatedByBusiness = (params: object) => axios.post('/manage/business/batchRelated', params);
export const queryBusinessDistrict = (params: object) => axios.get('/manage/announcement/getDistrictList', { params });