import { combineReducers } from 'redux';
import { businessAction, businessState } from '../../actions/bussiness';
import { objectToArray } from '../../utils/util';
import {
    SET_INFORFIELD
} from '../../constants/business';

const inforFieldList = (state: businessState = null , action: businessAction) => {
    switch (action.type) {
        case SET_INFORFIELD:
            action.payload = objectToArray(action.payload);
            return action.payload;
        default: 
            return state;
    }
}
// const inforField = combineReducers({
//     inforFieldList,
// });

export default inforFieldList;