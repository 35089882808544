export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export type GET_ROLE_LIST_TYPE = typeof GET_ROLE_LIST;
export const SET_ROLE_LIST = 'SET_ROLE_LIST';
export type SET_ROLE_LIST_TYPE = typeof SET_ROLE_LIST;


// export const GET_USER_AUTH = 'GET_USER_AUTH';
// export type GET_USER_AUTH_TYPE = typeof GET_USER_AUTH;
// export const SET_USER_AUTH = 'SET_USER_AUTH';
// export type SET_USER_AUTH_TYPE = typeof SET_USER_AUTH;

export const GET_USER_APP_AUTH = 'GET_USER_APP_AUTH';
export type GET_USER_APP_AUTH_TYPE = typeof GET_USER_APP_AUTH;
export const SET_USER_APP_AUTH = 'SET_USER_APP_AUTH';
export type SET_USER_APP_AUTH_TYPE = typeof SET_USER_APP_AUTH;