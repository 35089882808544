/**
 * @Author: EthanWong
 * @Date: 2020/06/11
 */

export const REQUEST_STATISTICS = 'REQUEST_STATISTICS';
export type REQUEST_STATISTICS = typeof REQUEST_STATISTICS;

export const RECEIVE_STATISTICS = 'RECEIVE_STATISTICS';
export type RECEIVE_STATISTICS = typeof RECEIVE_STATISTICS;

export const REQUEST_NOTIFICATION = 'REQUEST_NOTIFICATION';
export type REQUEST_NOTIFICATION = typeof REQUEST_NOTIFICATION;

export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION';
export type RECEIVE_NOTIFICATION = typeof RECEIVE_NOTIFICATION;

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export type UPDATE_NOTIFICATION = typeof UPDATE_NOTIFICATION;
