import { all, call, put, takeEvery } from 'redux-saga/effects';
import { 
    SET_MENU, 
    GET_MENU, 
    SET_APP_MENU, 
    GET_APP_MENU, 
    SET_BTN_MENU,
    GET_NONYCGOODS_MENU,
    SET_NONYCGOODS_APP_MENU,
    GET_NONYCGOODS_APP_MENU,
    SET_NONYCGOODS_MENU
} from '../../constants/menu';
import { manageMenu, manageAppMenu, manageGetNonYcGoodsMenuApp, manageGetNonYcGoodsMenu } from '../../api/api';
import { getBtnMenu } from '../../utils/util';

function* getMenu() {
    const { data } = yield call(manageMenu);
    if (data) {
        const btnMenu = getBtnMenu(data);
        yield put({ type: SET_BTN_MENU, payload: btnMenu });
        yield put({ type: SET_MENU, payload: data });
    }
}
function* getAppMenu() {
    const { data } = yield call(manageAppMenu);
    if (data) {
        yield put({ type: SET_APP_MENU, payload: data});
    }
}

function* getNonYcMenu() {
    const { data } = yield call(manageGetNonYcGoodsMenu);
    if (data) {
        yield put({ type: SET_NONYCGOODS_MENU, payload: data });
    }
}
function* getNonYcAppMenu() {
    const { data } = yield call(manageGetNonYcGoodsMenuApp);
    if (data) {
        yield put({ type: SET_NONYCGOODS_APP_MENU, payload: data});
    }
}


export default function* watchMenu() {
    yield all([
        takeEvery(GET_MENU, getMenu),
        takeEvery(GET_APP_MENU, getAppMenu),
        takeEvery(GET_NONYCGOODS_MENU, getNonYcMenu),
        takeEvery(GET_NONYCGOODS_APP_MENU, getNonYcAppMenu),
    ])
}
