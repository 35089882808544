import { connect } from 'react-redux';
import { GET_MENU, GET_APP_MENU, GET_NONYCGOODS_MENU, GET_NONYCGOODS_APP_MENU } from '../constants/menu';
import { Dispatch, Store } from '../interface';
import { CLEAR_USERINFO } from '../constants/userInfo';

const mapStateToProps = ( { userInfo, menu }: Store) => {
    return {
        userInfo,
        menu,
    }
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getMenu: () => {
        dispatch({type: GET_MENU});
    },
    getAppMenu: () => {
        dispatch({type: GET_APP_MENU});
    },
    getNonYcGoodsMenu: () => {
        dispatch({type: GET_NONYCGOODS_MENU});
    },
    getNonYcGoodAppMenu: () => {
        dispatch({type: GET_NONYCGOODS_APP_MENU});
    },
    clearUserInfo: () => {
        const global: any = window;
        dispatch({type: CLEAR_USERINFO});
        global.location.reload();
    }
})

const connectDecorator: Function = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default connectDecorator;
