import React from 'react';
import './jcUser.scss'
export default class JcAgreementUser extends React.Component {
    render() {
        return (
            <div className='box'>
                <div className='title'>精臣用户许可协议</div>
                <p className='time'>更新时间：2020年1月12日</p>
                <p>
                    &emsp;&emsp;感谢您选择使用由武汉精臣智慧标识科技有限公司（以下简称“精臣”）开发、运营的软件（以下简称为“精臣软件”，包括但不限于免费或收费版本的精臣软件、不同语言版本的精臣软件以及不同客户端的精臣软件）及精臣提供的服务！鉴于网络的特性，本软件将无可避免地与您产生直接或间接的互动关系，我们制定本《精臣隐私保护政策》（以下简称“政策”）并提醒您：在使用“精臣”软件及相关服务前，请务必仔细阅读并充分理解本《精臣隐私保护政策》，在确认充分理解并同意后方使用相关产品和服务。我们建议您仔细阅读并理解本政策全部内容，做出您认为适当的选择。
                    <span className='weight'>我们努力用通俗易懂、简明扼要的文字表达，并对本政策中与您的权益存在重大关系的条款和个人敏感信息，采用粗体字进行标注以提示您注意。</span>
                    如一旦您开始使用“精臣”软件及相关服务，则视为您对本政策内容的接受和认可。《精臣隐私保护政策》（以下简称为“本政策”）讲述了当您使用精臣软件或服务时，精臣收集、使用、保存、共享您的个人信息的目的、方式和范围、为您提供查询、更正和删除的渠道，以及您拒绝提供您的个人信息的后果等事项。
                </p>
                <p className='weight'>
                    &emsp;&emsp;精臣十分重视您的隐私，精臣将根据中华人民共和国（仅为本政策之目的，不包括香港、澳门特别行政区及台湾地区，以下称为“中国大陆”）相关法律法规的规定保护在您使用精臣软件、精臣服务的任何部分或全部的过程中精臣收集的个人信息。除非您同意并接受本政策的所有条款，否则您无权登录或使用精臣软件、精臣服务的任何部分或全部。您对精臣软件、精臣服务的任何部分或全部下载、安装、注册、开启、登录、浏览、购买、定制、使用等任何使用行为，意味着您同意精臣按照本政策收集、使用、保存、共享您的个人信息，且认可本政策全部条款并同意受其约束。
                </p>
                <u className='weight'>
                    &emsp;&emsp;如果我们变更本《精臣隐私保护政策》，我们会及时在“精臣软件”上发布或弹出最新版本《精臣隐私保护政策》，并提示您阅读变更内容。您点击同意并继续使用“精臣”软件及相关服务的，即意味着您同意本《精臣隐私保护政策》（含更新版本）内容，并同意我们按照本政策收集、保存、使用、共享、披露及保护您的相关信息。如对本精臣产品个人信息保护政策内容有任何疑问、意见或建议，您可通过网站客服邮箱kefu@jc-ai.com联系我们。
                </u>
                <p className='weight'>一、定义</p>
                <p >
                    <span className='weight'>个人信息：</span>
                    是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括个人身份信息（地址、通讯通信联系方式、通信记录和内容、网络身份识别信息（包括账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案等）、财产信息、交易信息、个人操作记录（软件使用记录、点击记录等）、个人位置信息等。
                </p>
                <p className='weight'>二、收集个人信息的范围及方式</p>
                <p>
                    1.注册、登陆精臣软件：<br />
                    <span className='weight'>1.1.您的基本身份信息，可能包括姓名、常用地址、联系方式、通讯录、邮箱等；</span><br />
                    <span className='weight'>1.2.您的网络身份识别信息，包括账户名、账户昵称、账户头像、邮箱地址以及与前述有关的密码；</span><br />
                    <span className='weight'>1.3您的其他信息，可能包括财产和位置信息。以上信息将用于单独或者结合识别用户身份。</span>您可以选择不提供某一或某些信息，但是这样可能导致您无法使用“精臣”软件的部分服务。<br />
                    <span className='weight'>1.4.注册账号注销：</span>在符合服务协议约定条件及国家相关法律法规规定的情况下，您可以通过网站客服邮箱kefu@jc-ai.com提交账号注销的申请或自行在精臣软件的用户“注销账号”页面按照相应的要求提交注册账号注销申请。<br />
                    <span className='weight'>1.5其他方式关联登录“精臣软件”：</span>我们会向第三方请求您的个人信息，我们将要求第三方说明信息来源并保障其提供的信息的合法性。对于我们需要但第三方无法提供的个人信息，我们仍会要求您提供。如果您拒绝提供，可能会导致您无法使用“精臣”软件的部分服务。<br />
                    <span className='weight'>2.1日志信息：</span>当您使用“精臣软件”服务时，我们会自动收集您对我们服务的使用情况，作为有关网络日志保存。包括您的扫描打印、模板编辑、调取历史数据、交易信息、IP地址、使用的打印设备、使用的语言、访问服务的日期和时间、个人操作记录、以及通过cookies或其他技术获得的信息。有助于用户获得更轻松的使用体验，了解用户的偏好，进行咨询或数据分析，改善产品服务即用户体验，提高广告效果，及时发现并防范安全风险等。您可以根据自己的偏好留存或删除cookie及信息。<br />
                    <span className='weight'>2.2设备信息：</span>当您使用精臣软件产品的打印服务时，为了提升用户体验，解决功能使用中遇到的障碍或问题，“精臣软件”可能会记录您使用的移动设备或您使用的用于接入“精臣软件”服务的其他程序所提供的个人常用设备信息，包括手机或打印机硬件型号、操作系统类型、操作系统版本号、设备配置、唯一设备标识符等描述个人常用终端设备基本情况的信息。<br />
                    <span className='weight'>2.3软件信息：</span>软件版本号、序列号、渠道号、邮箱域名、邮箱登录端口、登录协议或其他用于连接精臣服务的软件相关数据。<br />
                    <span className='weight'>2.4终端应用信息：</span>为了提升用户体验，当您开启特定“精臣”软件的服务功能时，我们将收集您相关的终端应用信息。例如，当您开启打印服务时，我们将会收集您的打印机及耗材信息，以精准匹配相关服务；我们将仅在实现服务目的必要权限内采集您的信息，并在本《政策》约束框架内使用、储存您的信息。您可以在设备的设置功能中选择关闭部分或全部权限。在不同设备中，上述权限的开启和关闭方式可能有所不同，具体请参考设备及系统开发方的说明或指引。<br />
                    <span className='weight'>3. 使用其它具体服务功能：<br /></span>
                    <span>3.1您在使用精臣产品时、上传和/或发布信息以及进行相关行为（例如分享、收藏）时，我们将收集您上传、发布或形成的信息，如该信息是公司或其他第三方的信息，您需确保您已经取得其授权同意。为提升您的服务体验，您还可以授权我们访问您的相机、照片、麦克风、通讯录及您的位置信息，以实现我们对这些功能所涉及的信息的收集和使用。如我们会基于上传的信息向您提供个性化的推荐，或功能。当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。</span><br />
                    <span>3.2在部分精臣软件电商服务项目中，为便于向您交付商品或服务，您至少需向我们提供收货人个人身份信息、姓名、收货地址、收货人、联系电话、支付状态信息。如果您拒绝提供此类信息，我们将无法完成相关交付服务。如您通过精臣电商平台为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向我们提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意。</span>
               3.3如您在我们的服务过程中产生支付结算，您可以选择我们的关联方或与我们合作的第三方支付机构（包括支付宝支付、微信支付等支付通道，以下称“支付机构”）所提供的支付服务。支付服务过程中我们可能需要将您的电商产品订单号与交易金额信息同这些支付机构共享以实现其确认您的支付指令并完成支付。<br />
                    <span>3.4为展示您账户的订单信息及保障您的售后权益，我们会收集您在使用精臣电商产品过程中产生的订单信息、交易和消费记录、用于向您展示及便于您对订单进行管理。如发生交易退费情形的，我们可能会要求您提供您的的银行账号信息，包括银行卡信息、支付宝、微信支付第三方账号信息用于为您处理退费请求。</span> <br />
                    <span> 3.5当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式及相关信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。 为确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，并将您的上述交易、支付、物流信息共享给相关的上述服务提供者 。</span><br />
                    <span>3.6当您参加“精臣软件”的有关营销活动时，我们根据活动需要可能会收集您的姓名、通信地址、联系方式、银行账号。这些信息是您收到转账或者礼品的基础，如果您拒绝提供这些信息，我们将无法向您转账或发放礼品。</span><br />
                    <span>4. 我们从第三方获得的您的信息</span><br />
                    <span>&emsp;&emsp;我们可能从第三方获取您授权共享的账户信息（账户名、账户昵称、账户头像、邮箱地址以及与前述有关的密码与密码保护问题和答案），并在您同意本《政策》后将您的第三方账户与您的精臣软件账户绑定，使您可以通过第三方账户直接登录并使用“精臣”软件及相关服务。我们将会依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律法规规定的前提下，使用您的上述个人信息。</span><br />
                    <span>&emsp;&emsp;如果为了向您提供服务而需要从第三方获取您的个人信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性。如果我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权范围，我们将征得您的明确同意。</span><br />
                    <span className='weight'>三、收集个人信息的使用方式和用途</span><br />
                    <span>精臣及其认为有必要知悉该等信息的主体可能将精臣收集的您的个人信息以下列方式使用或用作下列用途：</span><br />
                    <span>1.用于向您提供精臣软件、精臣服务。</span><br />
                    <span>2.为提高您使用精臣及/或精臣关联方、合作方提供的产品和服务的安全性，精臣可能会将您的信息提供给精臣关联方、合伙方，精臣及精臣关联方、合作方会使用您的信息用于身份验证、客户服务、安全防范、存档和备份等，以预防、发现、调查欺诈、危害安全、非法或违反与精臣的协议、政策或规则的行为，以保护您、精臣的其他用户、精臣或精臣的关联方、合作方及社会公众的合法权益。</span><br />
                    <span>3.改进我们的服务：您了解并同意，在收集您的信息后，如果我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用。</span><br />
                    <span>4.处理您的订购单。与电子商务订单相关的信息可用于处理订购单和相关的售后服务，包括客户支持和重新发货。 此外，订单号将用于交叉检查送货合作伙伴的订单和包裹的实际交付。 收件人信息，包括姓名、地址、电话号码和邮政编码将用于送货。电子邮件地址用于向用户发送包裹跟踪信息。 购买物品的清单用于打印发票，并允许客户确定包裹中的物品。</span><br />
                    <span>5.发送通知。精臣可能会不时使用您的个人信息来发送重要通知，如您不希望继续接收我们推送的消息，您可要求我们停止发出通知，例如：根据短信退订指引要求我们停止发送通知短信；但我们依法律规定或单项服务的服务协议约定发送消息的情形除外。</span><br />
                    <span>6.为您解决使用中的问题，提供顺畅的使用体验：为提高您使用我们及我们关联公司、合作伙伴提供服务的安全和稳定性，我们可能使用或整合我们所收集的您的信息，以及我们的合作伙伴取得您授权或依据法律共享的信息，来综合判断分析使用中的问题、原因、处置措施。</span><br />
                    <span>7.使精臣及其认为有必要知悉该等信息的主体更加了解您如何接入和使用精臣软件、精臣服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。</span><br />
                    <span>8.在不透露用户隐私数据的前提下，有权将您的个人信息提供给与精臣有合作关系的第三方，如您希望了解相关的第三方机构，可以发送邮件至精臣电子邮箱【kefu@jc-ai.com】，精臣将如实告知，如您不经前述途径咨询精臣，则视为您认可精臣根据行业通常的业务水准选择第三方合作机构），仅为实现合作目的而合理使用。</span><br />
                    <span>9.精臣可能使用您的信息，通过电子邮件、手机短信或其他方式向您推送商品、服务或消息，向您提供与您更加相关的广告以替代普遍投放的广告，例如向您展现或推荐相关程度更高（而非普遍推送）的搜索结果、信息流或者广告/推广信息结果。如您不希望精臣将您的信息用作接收营销信息的用途，您可以通过精臣在广告中提供的相关指示，要求精臣停止为上述用途使用您的个人信息。</span><br />
                    <span>10.有权利用您的个人信息对精臣软件、精臣服务的使用进行总体性及匿名的数据统计及分析，所得数据可供精臣及其认为有必要知悉该等信息的主体为实现本政策约定的目的使用。同时，精臣可能会与公众共享这些统计信息，以展示精臣软件、精臣服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。</span><br />
                    <span>11.精臣会将所收集的您的个人信息用于大数据分析。精臣可能对外公开并与有合作关系的第三方分享匿名化处理的大数据分析信息。</span><br />
                    <span>12.经您许可的其他用途。</span><br />
                    <span className='weight'>四、收集个人信息的共享、转让、公开披露</span><br />
                    <span>精臣及其认为有必要知悉该等信息的主体可能将精臣收集的您的个人信息以下列方式使用或用作下列用途：</span><br />
                    <span>1.共享</span><br />
                    <span>1.2.精臣可能会按照法律法规的要求或强制性的政府要求或司法裁定等，与相关的第三方共享您的个人信息。</span><br />
                    <span>1.3.您知悉并认可，精臣可能将您的个人信息与精臣关联公司共享。但精臣只会共享必要的个人信息。</span><br />
                    <span>1.4.与授权合作伙伴共享：仅为实现本政策中声明的若干目的，精臣的某些服务将由第三方合作伙伴提供。精臣可能会第三方与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。精臣仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。精臣的第三方合作伙伴无权将共享的个人信息用于任何其他用途。您同意精臣与如下第三方共享个人信息：
                    广告、分析服务类的合作伙伴。除非得到您的许可，否则精臣不会将您的个人身份信息与提供广告、分析服务的合作伙伴分享。精臣会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者精臣将这些信息进行汇总，以便它不会识别您个人。</span><br />
                    <span>2.转让</span><br />
                    <span>精臣不会将您的个人信息转让给任何公司、组织和个人，但以下情形除外：</span><br />
                    <span>2.1.事先获得您的明确授权或同意；</span><br />
                    <span>2.2.满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</span><br />
                    <span>2.3.如果精臣或精臣的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，精臣将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则精臣将要求该公司、组织重新向您征求授权同意。</span><br />
                    <span>3.公开披露</span><br />
                    <span>精臣仅会在以下情形下，公开披露您的个人信息：</span><br />
                    <span>3.1.获得您的明确同意；</span><br />
                    <span>3.2.在公布中奖活动名单时会脱敏展示中奖者手机号或账户登录名；</span><br />
                    <span>3.3.基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。</span><br />
                    <span>4.共享、转让、公开披露个人信息时事先征得授权同意的例外</span><br />
                    <span>在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span><br />
                    <span>4.1.与国家安全、国防安全直接相关的；</span><br />
                    <span>4.2.与公共安全、公共卫生、重大公共利益直接相关的；</span><br />
                    <span>4.3.与犯罪侦查、起诉、审判和判决执行等直接相关的；</span><br />
                    <span>4.4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span><br />
                    <span>4.5.您自行向社会公众公开的个人信息；</span><br />
                    <span>4.6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span><br />
                    <span>4.7.根据个人信息主体要求签订和履行合同所必需的；</span><br />
                    <span>4.8.用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span><br />
                    <span>4.9.法律法规规定的其他情形。</span><br />
                    <span>您知悉并认可，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span><br />
                    <span className='weight'>五、收集信息的保存、处理</span><br />
                    <span>精臣经精臣软件、精臣服务收集的您的个人信息可能会在精臣及其关联公司所在地进行存储和处理，也有可能会在精臣认为有必要知悉该等信息的主体处进行保存和处理。精臣仅在本政策所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。精臣会严格遵守中国大陆关于收集、使用、保存、共享用户个人信息的相关法律法规，使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。由于技术发展日新月异，本公司对信息安全采取的合理保护措施可能由于外部原因而被规避或逾越，因此本公司在法律限度内提供信息安全的合理保证。</span><br />
                    <span className='weight'>六、查询、更正、删除信息的渠道</span><br />
                    <span>如果您需要查询、修改、更正或删除您的个人信息，或对您的个人信息保护问题有任何疑问或投诉举报建议，您可以通过发送邮件至精臣电子邮箱【kefu@jc-ai.com】，精臣将及时有效地处理您的问题。</span><br />
                    <span className='weight'>七、对未成年人信息的保护</span><br />
                    <span>精臣非常重视对未成年人信息的保护，精臣不会有意向18周岁以下的未成年人收集或请求个人信息。若您是18周岁以下的未成年人，请您不要向精臣发送您的个人信息；同时，您在使用精臣软件、精臣服务前，应事先取得您的家长或法定监护人的同意，建议您的父母或监护人仔细阅读本政策，同时建议您在您的父母或监护人的指导下使用精臣软件、精臣服务。一旦您以任何方式下载、安装、使用精臣软件、登录、注册、使用精臣服务的任何部分或全部，即表明您的家长或法定监护人同意您使用精臣软件、精臣服务并同意本政策的全部内容，同时允许精臣依据本政策收集、使用、保存、共享您的个人信息。如果父母或监护人有理由相信未成年人未经他们事先同意而向精臣提交了未成年人的个人信息，请联系精臣以确保删除此类个人信息。</span><br />
                    <span className='weight'>八、关于免责</span><br />
                    <span className='weight'>&emsp;&emsp;精臣就下列相关事宜的发生，不承担任何法律责任：</span><br />
                    <span className='weight'>1.由于您的个人原因将精臣软件的激活码、精臣服务的个人账户密码告知他人或与他人共享，由此导致的您的个人信息的泄露。</span><br />
                    <span className='weight'>2.精臣软件中的部分服务是由与精臣或关联公司有合作关系的第三方提供的，由第三方提供的服务须受第三方自行制定、发布的服务条款及个人信息保护政策（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于精臣所收集的任何信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，精臣对任何第三方使用由您提供的信息不承担任何责任。</span><br />
                    <span className='weight'>3.任何由于黑客攻击、电脑病毒入侵或政府管制而造成的暂时性服务关闭。</span><br />
                    <span className='weight'>4.因不可抗力导致的任何后果。</span><br />
                    <span className='weight'>5.精臣事先获得您的明确授权而公布的您的个人信息。</span><br />
                    <span className='weight'>6.根据相关的法律法规、相关政府主管部门或相关证券交易所的要求提供、公布您的个人信息。</span><br />
                    <span className='weight'>7.为维护社会公众利益而提供、公布您的个人信息。</span><br />
                    <span className='weight'>8.为维护精臣的合法权益而提供、公布您的个人信息。</span><br />
                    <span className='weight'>9.其他非因精臣原因导致的您的个人信息的泄露。</span><br />
                    <span className='weight'>九、本政策的适用范围</span><br />
                    <span>1.本政策仅适用于我们所收集、保存、使用、共享、披露信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方使用由您提供的信息不承担任何责任。</span><br />
                    <span className='weight'>2.精臣软件、精臣服务可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。因此，强烈建议您花时间阅读该第三方的隐私政策，就像阅读本政策一样。精臣不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。本政策不适用通过精臣软件、精臣服务链接的其他网站。</span><br />
                    <span className='weight'>十、特别提示</span><br />
                    <span className='weight'>本政策所述的精臣认为有必要知悉该等信息的主体包括精臣的员工及其他精臣认为有必要知悉该等信息的个人、机构或组织，您知悉并同意精臣通过精臣软件、精臣服务收集您的个人信息，精臣可以向其认为有必要知悉该等信息的主体披露，但是精臣保证其认为有必要知悉该等信息的主体都只能在为实现本政策约定的目的使用该等信息。 本政策各条款的标题仅为方便用户阅读列出，各条款表达的具体信息仍以各条款载明的实际内容为准。</span><br />
                    <span className='weight'>精臣认为，且您亦同意，您在使用精臣软件、精臣服务前已经仔细阅读并完全理解本政策的全部内容，一旦您使用精臣软件、精臣服务，则表明您同意本政策的全部内容且承诺受本政策之约束。
                    争议解决：当您因为本政策的实施与“精臣”软件或相关服务的公司产生任何纠纷时，双方应首先协商友好解决；若不能协商解决，您同意将争议提交至武汉市有管辖权的人民法院诉讼解决。</span><br />
                </p>
            </div >
        )
    }
}