import React from 'react';
import { Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import { asyncRoutes } from '../../router/routes';
import { MenuItem, RouteComponentProps, RoutesType } from '../../interface';
import { filterPowerMenu, darkClone } from '../../utils/util';
const { SubMenu } = Menu;

interface IProps extends RouteComponentProps {
    menu: MenuItem[] | null;
}
interface IState {
    openKeys: string[];
    currentKey: string;
}

class MyMenu extends React.Component<IProps, IState> {
    readonly state = {
        openKeys: [],
        currentKey: '',
    } as IState
    componentDidMount() {
    }
    render() {
        return (
            <Menu
                mode="inline"
                selectedKeys={[this.props.history.location.pathname]}
                defaultSelectedKeys={[this.props.history.location.pathname]}//this.props.history.location.pathname
                defaultOpenKeys={[this.props.history.location.pathname]} //[this.props.history.location.pathname.split('/').filter((item,index) => index < 2).join('/')]
                onOpenChange={this.onOpenChange}
            >
                {filterPowerMenu(darkClone(asyncRoutes), this.props.menu).map(sub => {
                    return sub.children ? <SubMenu
                        key={sub.path}
                        title={
                            <span>
                                <i
                                    style={{ marginRight: '20px', verticalAlign: 'middle' }}
                                    className={`iconfont ${sub.icon ? sub.icon : ''}`} />
                                <span style={{ verticalAlign: 'middle' }}>
                                    {sub.name}
                                </span>
                            </span>
                        }
                    >
                        {sub.children ? sub.children.map(item => {
                            return <Menu.Item key={item.path} onClick={() => this.menuClick(item)}>
                                <span style={{ paddingLeft: '20px' }}>{item.name}</span>
                            </Menu.Item>
                        }) : null}
                    </SubMenu> :
                        (
                            <Menu.Item
                                key={sub.path}
                                onClick={() => this.menuClick(sub)}>
                                <span>
                                    <i
                                        style={{ marginRight: '20px', verticalAlign: 'middle' }}
                                        className={`iconfont ${sub.icon ? sub.icon : ''}`} />
                                    <span style={{ verticalAlign: 'middle' }}>
                                        {sub.name}
                                    </span>
                                </span>
                            </Menu.Item>
                        )
                })}
            </Menu>
        )
    }

    onOpenChange = (openKeys: string[]) => {
        const latestOpenKey = openKeys.find((key: string) => this.state.openKeys.indexOf(key) === -1);
        if (latestOpenKey && asyncRoutes.map((item) => item.path).indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    }
    menuClick = (item: RoutesType) => {
        // if (item.path === this.state.currentKey) {
        //     return;
        // }
        this.setState({
            currentKey: item.path,
        }, () => {
            if (item.path == "/goods/list" &&
                this.props.location.pathname !== "/goods/list" &&
                this.props.location.pathname.indexOf("/goods/add") == -1 &&
                this.props.location.pathname.indexOf("/goods/detail") == -1 &&
                this.props.location.pathname.indexOf("/goods/edit") == -1 &&
                this.props.location.pathname.indexOf("/goods/import") == -1
            ) {
                let data = JSON.parse(localStorage.getItem('GoodList_Query_Data') || '{}')
                localStorage.removeItem('GoodList_Query_Data_ids')
                localStorage.removeItem('GoodList_Query_Data_keywords')
                localStorage.removeItem('GoodList_Query_Data_idList')
                localStorage.removeItem('GoodList_Query_Data')
                localStorage.setItem('GoodList_Query_Data_page', '1')
                localStorage.setItem('GoodList_Query_Data_limit', '15')
                if (data && data.order) {
                    localStorage.setItem('GoodList_Query_Data', JSON.stringify({
                        order: data.order || 'create_time'
                        // districtIds: [],
                        // end_time: "",
                        // start_time: ""
                    }))
                }
            }
            this.props.history.push(item.path);
        })
    }
    routesToDefaultMap = (routes: RoutesType[]) => {
        let openMap: { [index: string]: string } = {};
        let selectMap: { [index: string]: string } = {};
        for (let level1 of routes) {
            let level1Key = level1.path;
            openMap[this.routesKeyToTwo(level1.path)] = level1Key;
            if (level1.children) {
                for (let level2 of level1.children) {
                    let level2Key = level2.path;
                    selectMap[this.routesKeyToTwo(level2.path)] = level2Key;
                    openMap[this.routesKeyToTwo(level2.path)] = level1Key;
                    if (level2.children) {
                        for (let level3 of level2.children) {
                            selectMap[this.routesKeyToTwo(level3.path)] = level2Key;
                            openMap[this.routesKeyToTwo(level3.path)] = level1Key;
                        }
                    }
                }
            }
        }
        return {
            openMap,
            selectMap
        }
    }
    routesKeyToTwo = (key: string) => {
        return key.split('/').filter((item: any, index: number) => index < 3).join('/');
    }
}

export default withRouter(MyMenu);