import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { UserInfo } from '../interface';
import connect from '../connect/BeforeRouter';
import MainLayout from '../container/Layout';
import Login from '../container/Login';
import JcAgreement from '../container/JC'
import JcAgreementUser from '../container/JCUser'
interface IProps {
    setUserInfo: (payload: UserInfo | null) => void;
    userInfo: UserInfo;
}


class BeforeRouter extends React.Component<IProps> {
    state = {
        isJcAgreement: ''
    }
    componentDidMount() {
        let userInfo: string | null = localStorage.getItem('tobacco-user') || null;
        this.props.setUserInfo(userInfo ? JSON.parse(userInfo) : null);
        this.setState({
            isJcAgreement: window.location.hash.split('#/')[1]
        })
    }

    routefilter = () => {
        const { isJcAgreement } = this.state
        const { userInfo } = this.props;
        if (isJcAgreement === 'jcAgreement') {
            return (
                <Switch>
                    <Route exact={true} path={'/jcAgreement'} component={JcAgreement} />
                </Switch>
            )
        } else if (isJcAgreement === 'jcAgreementUser') {
            return (
                <Switch>
                    <Route exact={true} path={'/jcAgreementUser'} component={JcAgreementUser} />
                </Switch>
            )
        } else {
            return (
                <div style={{
                    minWidth: '1365px'
                }}>
                    {userInfo ? <MainLayout /> : <Login />}
                </div>
            )
        }
    }
    render() {
        const { userInfo } = this.props;
        const { isJcAgreement } = this.state;
        return (
            <Router>
                {/* {isJcAgreement === 'jcAgreement' || isJcAgreement === 'jcAgreementUser' ?
                    <Switch>
                        <Route exact={true} path={'/jcAgreement'} component={JcAgreement} />
                    </Switch>
                    : <div style={{
                        minWidth: '1365px'
                    }}>
                        {userInfo ? <MainLayout /> : <Login />}
                    </div>} */}
                {this.routefilter()}
            </Router>
        );
    }
}




export default connect(BeforeRouter);
