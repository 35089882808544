import { connect } from 'react-redux';
import { Dispatch } from '../interface';
import { CLEAR_USERINFO } from '../constants/userInfo';

export interface ConnectProps {
    clearUserInfo: () => void;
}
const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearUserInfo: () => {
        const global: any = window;
        dispatch({type: CLEAR_USERINFO});
        global.location.reload();
    }
})

const connectDecorator: Function = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default connectDecorator;
