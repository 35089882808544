import { message as antdMessage } from 'antd';
import { manageUserCheckUserExist, getCheckBarCodeExist, checkIsExists, getCheckItemBarCodeExist, getCheckBarCode, checkUserNonGoodsField, nonGoodscheckUser } from '../api/api';
import { resCode } from './code';

export const phone = (message?: string) => {
    return {
        trigger: 'blur',
        validator: (rule: any, value: string, callback: (message?: any) => void) => {//手机号
            if (!value) {
                return callback();
            }
            let flag = /^1[0-9]\d{9}$/.test(value);   //直接匹配 1开头的 11位数字 by:hr
            if (flag) {
                return callback();
            }
            return callback(new Error(message ? message : '请填写正确的手机号'));
        }
    }
}

export const password = (message?: string) => {
    return {
        trigger: 'blur',
        validator: (rule: any, value: string, callback: (message?: any) => void) => {//密码
            if (!value) {
                return callback();
            }
            let v = value.toString().trim();
            let flag = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(v);   //180530  密码由数字和字母组成  by:jw
            if (flag) {
                return callback();
            }
            return callback(new Error(message ? message : '密码格式由8-16位数字和字母组成!'));
        }
    }
}

// 验证码校验
export const captcha = (message?: string) => {
    return {
        trigger: 'blur',
        validator: (rule: any, value: string, callback: (message?: any) => void) => {//验证码
            if (!value) {
                return callback();
            }
            const captcha = sessionStorage.getItem('captcha');
            if (value.toLocaleLowerCase() == captcha) {
                return callback();
            }
            return callback(new Error(message ? message : '请输入正确验证码'));
        }
    }
}

//重复密码验证
export const passwordRepeat = (valueRepeat?: number | string, message?: string) => {
    return {
        trigger: 'blur',
        validator: (rule: any, value: string, callback: (message?: any) => void) => {//密码
            if (!value) {
                return callback();
            }
            let v = value.toString().trim();
            let flag = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(v);   //180530  密码由数字和字母组成  by:jw
            if (flag) {
                if (valueRepeat === value) {
                    return callback();
                } else {
                    return callback(new Error(message ? message : '二次密码不一致'))
                }
            }
            return callback(new Error(message ? message : '密码格式由8-16位数字和字母组成!'));
        }
    }
}
/**
 * 新增时验证某个字段是否已存在,验证用户名
 * @param type
 * @param iMessage
 */
export const addRoleFetchVerification = (type: string, iMessage: string, oldValue?: string, isTrue?: boolean) => {
    return {
        trigger: 'blur',
        validator: (rule: any, value: string, callback: (message?: any) => void) => {//密码
            if (!value) {
                return callback();
            }
            if (oldValue && value === oldValue) {
                return callback();
            }
            manageUserCheckUserExist({
                field: type,
                value,
            })
                .then((res: any) => {
                    if (res.code === resCode.success) {
                        if (!res.data.exist && !isTrue) {
                            return callback();
                        } else if (res.data.exist && isTrue) {
                            return callback();
                        }
                    } else {
                        antdMessage.warning(res.message);
                    }
                    callback(new Error(iMessage));
                })
        }
    }
}
/**
 * 编辑时验证某个字段是否已存在
 * @param type  验证的字段名
 * @param iMessage  提示信息
 * @param detailObj   详情
 */
export const EditRoleFetchVerification = (type: string, iMessage: string, detailObj: any) => {
    return {
        trigger: 'blur',
        validator: (rule: any, value: string, callback: (message?: any) => void) => {//密码
            if (!value) {
                return callback();
            }
            if (value === detailObj[type]) {
                return callback();
            }
            manageUserCheckUserExist({
                field: type,
                value,
            })
                .then((res: any) => {
                    if (res.code === resCode.success) {
                        if (!res.data.exist) {
                            return callback();
                        }
                    } else {
                        antdMessage.warning(res.message);
                    }
                    callback(new Error(iMessage));
                })
        }
    }
}
/* 必填 */
export const require = (message: string) => {
    return {
        required: true,
        trigger: 'blur',
        message,
    };
};
/* 最小长度 */
export const min = (message: string, minLength: number | undefined) => {
    return {
        min: minLength,
        message,
        trigger: 'blur',
    };
};
/* 最大长度 */
export const max = (message: string, maxLength: number | undefined) => {
    return {
        max: maxLength,
        message,
        trigger: 'blur',
    };
};
/* 正则 */
export const pattern = (message: string, reg: RegExp) => {
    return {
        pattern: reg,
        message,
        trigger: 'blur',
    };
};
/* 数字 */
export const number = (message: string) => {
    return {
        pattern: /^[0-9]*$/,
        message,
        trigger: 'blur',
    }
}
/* 不允许有空格及特殊字符 */
export const noSpaceSpecial = (message: string) => {
    return {
        validator: (rule: any, value: any, callback: any) => {
            if (value === undefined || value === '' || value === null) {
                callback();
                return;
            }
            if (value.trim && !value.trim()) {
                callback(new Error(message));
                return;
            }
            const regSpecial = /[·！#￥（——）：；“”‘，|《。》？【】[\]`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im;
            const regSpace = /^\S*$/;
            if (regSpace.test(value) && !regSpecial.test(value)) {
                callback();
                return;
            }
            callback(new Error(message));
            return;
        },
        trigger: 'blur',
    };
};
//验证更新版本
export const patternIsExists = (message: string, callback1: any) => {
    return {
        validator: (rule: any, value: any, callback: any) => {
            if (value) {
                checkIsExists({
                    old_version: value,
                }).then((res: any) => {
                    if (res.code === 100000) {
                        callback();
                        callback1(true, value)
                        return;
                    } else {
                        callback(new Error(res.message));
                        callback1(false, value)
                        return;
                    }
                })
            } else {
                callback();
            }

        },
        trigger: 'blur',
    };
};
//验证烟草商品条码(盒包装)是否存在
export const patternAndAxios = (message: string, min: any, max: any) => {
    return {
        validator: (rule: any, value: any, callback: any) => {
            // if (value === undefined || value === '' || value === null) {
            //     callback();
            //     return;
            // }
            // if (value.trim && !value.trim()) {
            //     callback(new Error(message));
            //     return;
            // }
            if (min.test(value)) {
                getCheckBarCodeExist({
                    bar_code: value,
                    districtId: max
                }).then((res: any) => {
                    if (res.code === 100000) {
                        callback();
                        return;
                    } else {
                        callback(new Error(res.message));
                        return;
                    }
                })
            } else {
                callback(new Error(message));
                return;
            }
        },
        trigger: 'blur',
    };
};
//验证烟草商品条码(盒包装)是否存在
export const nonGoodsAndAxios = (message: string, min: any, max: any) => {
    return {
        validator: (rule: any, value: any, callback: any) => {
            // if (value === undefined || value === '' || value === null) {
            //     callback();
            //     return;
            // }
            // if (value.trim && !value.trim()) {
            //     callback(new Error(message));
            //     return;
            // }
            if (min.test(value)) {
                getCheckBarCode({
                    bar_code: value,
                    id: max
                }).then((res: any) => {
                    if (res.code === 100000) {
                        callback();
                        return;
                    } else {
                        callback(new Error(res.message));
                        return;
                    }
                })
            } else {
                callback(new Error(message));
                return;
            }
        },
        trigger: 'blur',
    };
};
//验证烟草商品条码(条包装)是否存在
export const tiaoAndAxios = (message: string, min: any, max: any) => {
    return {
        validator: (rule: any, value: any, callback: any) => {
            if (!value) {
                callback();
                return;
            }
            if (min.test(value)) {
                getCheckItemBarCodeExist({
                    item_bar_code: value,
                    districtId: max
                }).then((res: any) => {
                    if (res.code === 100000) {
                        callback();
                        return;
                    } else {
                        callback(new Error(res.message));
                        return;
                    }
                })
            } else {
                callback(new Error(message));
                return;
            }
        },
        trigger: 'blur',
    };
};
export const numberLimit = (message: string, min: any, max: any) => {
    return {
        validator: (rule: any, value: any, callback: any) => {
            if (value === undefined || value === '' || value === null) {
                callback();
                return;
            }
            if (value.trim && !value.trim()) {
                callback(new Error(message));
                return;
            }
            if (min.test(value) && max.test(value)) {
                callback();
                return;
            }
            callback(new Error(message));
            return;
        },
        trigger: 'blur',
    }
}
export const numberLimit2 = (message: string, min: any, max: any) => {
    return {
        validator: (rule: any, value: any, callback: any) => {
            if (value === undefined || value === '' || value === null) {
                callback();
                return;
            }
            if (value.trim && !value.trim()) {
                callback(new Error(message));
                return;
            }
            if (min <= value && max >= value) {
                callback();
                return;
            }
            callback(new Error(message));
            return;
        },
        trigger: 'blur',
    }
}
//验证非烟字段新增用户名手机号绑定

export const checkNonGoodsField = (type: string) => {
    return {
        trigger: 'blur',
        validator: (rule: any, value: string, callback: (message?: any) => void) => {
            if (!value) {
                return callback();
            }
            checkUserNonGoodsField({
                [type]: value,
            })
                .then((res: any) => {
                    if (res.code === resCode.success) {
                        if (!res.data.exist) {
                            return callback();
                        }
                    } else {
                        // antdMessage.warning(res.message);
                        return callback(new Error(res.message))
                    }
                })
        }
    }
}
/* 验证手机号用户名同时存在 */
export const iphoneAndUser = (that: any, id?: any, isTemplate?: boolean) => {
    return {
        trigger: 'blur',
        validator: (rule: any, value: any, callback: any) => {
            if (!value) {
                return callback();
            }
            if (that.props.add) {
                let ids = id ? id.id : ''
                checkUserNonGoodsField({
                    username: value,
                    id: ids
                })
                    .then((res: any) => {
                        if (res.code === resCode.success) {
                            if (!res.data.exist) {
                                return callback();
                            }
                        } else {
                            return callback(new Error(res.message))
                        }
                    })
            } else {
                if (isTemplate) {
                    nonGoodscheckUser({
                        username: value,
                        template_id: id
                    })
                        .then((res: any) => {
                            if (res.code === resCode.success) {
                                if (!res.data.exist) {
                                    return callback();
                                }
                            } else {
                                return callback(new Error(res.message))
                            }
                        })
                } else {
                    checkUserNonGoodsField({
                        username: value,
                    })
                        .then((res: any) => {
                            if (res.code === resCode.success) {
                                if (!res.data.exist) {
                                    return callback();
                                }
                            } else {
                                return callback(new Error(res.message))
                            }
                        })
                }

            }
        },
    };
};
