import { combineReducers } from 'redux';
import { 
    MenuAction, 
    MenuState, 
    AppMenuState, 
    AppMenuAction, 
    BtnMenuAction,
    NonYcAppMenuAction,
    NonYcMenuAction
} from '../../actions/menu';
import {
    SET_MENU, 
    SET_APP_MENU, 
    SET_BTN_MENU,
    SET_NONYCGOODS_MENU,
    SET_NONYCGOODS_APP_MENU
} from '../../constants/menu';

const pcMenu = (state: MenuState = null, action: MenuAction) => {
    switch (action.type) {
        case SET_MENU:
            return action.payload;
        default: 
            return state;
    }
}

const appMenu = (state: MenuState = null, action: AppMenuAction) => {
    switch (action.type) {
        case SET_APP_MENU:
            return action.payload;
        default: 
            return state;
    }
}

const pcBtnMenu = (state: string[] | null = null, action: BtnMenuAction) => {
    switch (action.type) {
        case SET_BTN_MENU:
            return action.payload;
        default: 
            return state;
    }
}

const nonYcGoodsMenu = (state: MenuState = null, action: NonYcMenuAction) => {
    switch (action.type) {
        case SET_NONYCGOODS_MENU:
            return action.payload;
        default: 
            return state;
    }
}

const nonYcGoodsAppMenu = (state: MenuState = null, action: NonYcAppMenuAction) => {
    switch (action.type) {
        case SET_NONYCGOODS_APP_MENU:
            return action.payload;
        default: 
            return state;
    }
}

const menu = combineReducers({
    pcMenu,
    appMenu,
    pcBtnMenu,
    nonYcGoodsMenu,
    nonYcGoodsAppMenu,
});

export default menu;