
export const SET_MENU = 'SET_MENU';
export type SET_MENU_TYPE = typeof SET_MENU;
export const GET_MENU = 'GET_MENU';
export type GET_MENU_TYPE = typeof GET_MENU;
export const SET_APP_MENU = 'SET_APP_MENU';
export type SET_APP_MENU_TYPE = typeof SET_APP_MENU;
export const GET_APP_MENU = 'GET_APP_MENU';
export type GET_APP_MENU_TYPE = typeof GET_APP_MENU;


export const SET_BTN_MENU = 'SET_BTN_MENU';
export type SET_BTN_MENU_TYPE = typeof SET_BTN_MENU;

export const GET_NONYCGOODS_MENU = 'GET_NONYCGOODS_MENU';
export type GET_NONYCGOODS_MENU_TYPE = typeof GET_NONYCGOODS_MENU;

export const SET_NONYCGOODS_MENU = 'SET_NONYCGOODS_MENU';
export type SET_NONYCGOODS_MENU_TYPE = typeof SET_NONYCGOODS_MENU;

export const GET_NONYCGOODS_APP_MENU = 'GET_NONYCGOODS_APP_MENU';
export type GET_NONYCGOODS_APP_MENU_TYPE = typeof GET_NONYCGOODS_APP_MENU;

export const SET_NONYCGOODS_APP_MENU = 'SET_NONYCGOODS_APP_MENU';
export type SET_NONYCGOODS_APP_MENU_TYPE = typeof SET_NONYCGOODS_APP_MENU;