import React, { CSSProperties, FC, useEffect } from 'react';
import {
    Col,
    Row,
    Card,
    List,
    Statistic,
    Icon,
    Badge, message,
    Typography
} from 'antd';
import imgIndex from '../../assets/index.png';
import numeral from 'numeral';
import './index.scss';
import { textFormat } from '../../utils/utilx';
import connect, { IHomeConnectProps } from '../../connect/home';
import { HomeModelState } from '@/actions/home';
import { IPageVO } from '@/VO/common';
import moment from 'moment';
import { formatYMDhms } from '@/constants/common';
import { changeHomeNotificationRead } from '@/api/api';
import { AxiosResponse } from 'axios';
import { IResponse } from '@/interface/util';
import { convertAxiosData } from '@/utils/util';
import { EnumResponse } from '@/constants/response';
import { IHomeNotificationItemDTO } from '@/DTO/home';
import _ from 'lodash';

const { Paragraph } = Typography;

const welcome: string[] = ['欢', '迎', '使', '用', '价', '签', '管', '家', '管', '理', '后', '台'];

const Title: FC<{ title: string }> = (props) => (
    <p className="home-title">{props.title}</p>
)

interface IStatisticsProps {
    title: string;
    statisticsNumber: number;
    ratio: string;
    unit: string;
}
const StatisticsFunc: FC<IStatisticsProps> = (props) => {
    const ratio = parseInt(props.ratio, 10);
    const valueStyle: CSSProperties = ratio > 0 ? { color: '#3f8600'} : { color: '#333' };
    const prefix = ratio > 0 ? <Icon style={valueStyle} type="arrow-up"/> :
            ratio === 0 ? <Icon style={valueStyle} type="arrow-right"/> : <Icon style={valueStyle} type="arrow-down"/>;
    return (
        <section className="home-statistics">
            <Title title={props.title}/>
            <div style={{ marginLeft: 10 }}>
                <p className="total">{textFormat(props.statisticsNumber, { name: 'integer' })}<i>{props.unit}</i></p>
                <section>
                    <span style={{ marginRight: 10, color: '#707070' }}>相比前两日</span>
                    <span>{prefix}{props.ratio}</span>
                </section>
            </div>
        </section>
    )
};

interface IHomeProps extends Required<IHomeConnectProps['home']> {
    queryStatistics: () => void;
    queryNotification:(page: IPageVO) => void;
    updateNotification: (id: number) => Array<IHomeNotificationItemDTO>;
}
const HomeFunc: FC<IHomeProps> = (props) => {
    const { queryStatistics, queryNotification, updateNotification } = props;
    useEffect(() => {
        queryStatistics();
        queryNotification({
            page: 1,
            limit: 15,
        });
    }, []);
    
    const read = (id: number) => {
        const { notification: { list }} = props;
        const checkRead: boolean = list.filter(i => i.id === id)[0].is_read === 1;
        if (checkRead) {
            changeHomeNotificationRead({
                id,
            }).then((res: AxiosResponse<IResponse<Array<number>>>) => {
                const { data: { code, message: msg, data }} = convertAxiosData(res);
                if (code === EnumResponse.success) {
                    updateNotification(id);
                } else {
                    message.warning(msg);
                }
            });
        }
    };
    const {
        statistics: {
            yesterdayPrintNum,
            yesterdayGoodsNum,
            goodsWithBeforeYesterday,
            printWithBeforeYesterday
        },
        notification: {
            list
        }
    } = props;
    return (
        <div className="home">
            <section className="top">
                <img src={imgIndex} alt="Home Index Image"/>
                <div className="text">
                    <Row type="flex" justify="space-between">
                        {welcome.map((item, idx) => <Col key={idx}>{item}</Col>)}
                    </Row>
                </div>
            </section>

            <section className="content">
                <Row type="flex" justify="space-between">
                    <Col style={{ flex: 1 }}>
                        <Row>
                            <Card bordered={false}>
                                <StatisticsFunc title={"昨日打印张数"}
                                                statisticsNumber={yesterdayPrintNum}
                                                ratio={printWithBeforeYesterday}
                                                unit={'张'}
                                />
                            </Card>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Card bordered={false}>
                                <StatisticsFunc title={"昨日打印商品数"}
                                                statisticsNumber={yesterdayGoodsNum}
                                                ratio={goodsWithBeforeYesterday}
                                                unit={'件'}
                                />
                            </Card>
                        </Row>
                    </Col>
                    <Col style={{ flex: 1, marginLeft: 20 }}>
                        <section className="tipsList">
                            <Title title="消息通知"/>
                            <List
                                size="small"
                                dataSource={list}
                                renderItem={item => <List.Item
                                    style={{ marginTop: 20 }}
                                    onClick={() => read(item.id)}
                                >
                                    <Row style={{ flex:1 }} align="middle" type={"flex"} justify={'space-between'}>
                                        <Col span={1}>
                                            {
                                                item.type === 2 ?
                                                    <Icon theme={"filled"} style={{ color: '#1aa86b', marginRight: 5 }} type={"snippets"}/> :
                                                    <Badge dot={item.is_read === 1}>
                                                        <Icon theme={"filled"} style={{ color: '#44a0ff', marginRight: 5 }} type={"notification"}/>
                                                    </Badge>
                                            }
                                        </Col>
                                            <Paragraph ellipsis={{ rows: 1 }}>
                                                {item.title}
                                            </Paragraph>
                                            &nbsp;
                                            {item.type === 2 ? <Badge count={item.claim_invoice_num} /> : null}
                                        <Col span={8}>
                                            <span style={{ color: '#999' }}>{item.order_time}</span>
                                        </Col>
                                    </Row>
                                </List.Item>}
                            />
                        </section>
                    </Col>
                </Row>
            </section>
        </div>
    )
};

export default connect(HomeFunc);
