/**
 * @Author: EthanWong
 * @Date: 2020/06/11
 */
import { combineReducers } from 'redux';
import { HomeAction, HomeModelState } from '@/actions/home';
import { RECEIVE_NOTIFICATION, RECEIVE_STATISTICS, REQUEST_NOTIFICATION, REQUEST_STATISTICS, UPDATE_NOTIFICATION } from '@/constants/home';

const init = {
    yesterdayPrintNum: 0,
    yesterdayGoodsNum: 0,
    printWithBeforeYesterday: '0%',
    goodsWithBeforeYesterday: '0%',
};
const statistics = (state: HomeModelState['data'] = init, action: HomeAction) => {
    switch (action.type) {
        case REQUEST_STATISTICS:
            return {...state};
        case RECEIVE_STATISTICS:
            return action.payload;
        default:
            return state;
    }
}

const notification = (state: HomeModelState['notification'] = {
    last_page: 1,
    page: 1,
    limit: 10,
    list: [],
    total: 0,
}, action: HomeAction) => {
    switch (action.type) {
        case REQUEST_NOTIFICATION:
            return {...state};
        case RECEIVE_NOTIFICATION:
            return action.payload;
        case UPDATE_NOTIFICATION:
            return {
                ...state,
                list: state.list.map(i => {
                    if (i.id === action.payload) {
                        return { ...i, is_read: 2 };
                    } else {
                        return i;
                    }
                })
            }
        default:
            return state;
    }
}

const index = combineReducers({
    statistics,
    notification
});

export default index;