import React from 'react';
import { Row, Col, Dropdown, Icon, Menu, Modal } from 'antd';
import { changeVisible } from '../../utils/util';
import { history } from '../../utils/history';
import { UserInfo } from '../../interface';
import ChangePassword from './ChangePassword';


interface IProps {
    userInfo: UserInfo;
    clearUserInfo: () => void;
}
const confirm = Modal.confirm;
class Header extends React.Component<IProps> {
    state = {
        visible: {
            password: false,
        },
    }
    render() {
        const menu = (
            <Menu>
                <Menu.Item key="1" onClick={this.changeUser}>个人中心</Menu.Item>
                {/* <Menu.Item key="2" onClick={this.changePassword}>修改密码</Menu.Item> */}
                <Menu.Item key="3" onClick={this.logout}>退出账号</Menu.Item>
            </Menu>
        )
        const { userInfo } = this.props;
        const { password } = this.state.visible;
        return (
            <Row type="flex" justify="space-between">
                <Col className="f-fl" />
                <Col
                    style={{
                        padding: '0 25px',
                        color: '#fff',
                        fontSize: '16px'
                    }}>
                    <Dropdown overlay={menu}>
                        <span>
                            {userInfo.role_name} {userInfo.username} <Icon type="down" />
                        </span>
                    </Dropdown>

                </Col>
                <Modal
                    title="修改密码"
                    visible={password}
                    width='520px'
                    footer={null}
                    closable={false} >
                    <ChangePassword
                        close={this.changePasswordClose}
                        save={this.changePasswordSave} />
                </Modal>
            </Row>
        )
    }
    logout = () => {
        confirm({
            cancelText: '取消操作',
            okText: '确定退出',
            onOk: () => {
                this.props.clearUserInfo();
            },
            title: '是否要退出登录',
        })
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }
    changeUser = () => {
        history.push('/userInfoCenter')
    }
    changePassword = () => {
        changeVisible(this, 'password');
    }
    changePasswordClose = () => {
        changeVisible(this, 'password', true);
    }
    changePasswordSave = () => {
        this.changePasswordClose();
    }
}

export default Header;