import React from 'react';
import logo from '../../assets/logo.png';
import bg from '../../assets/login_bg.jpg';
import {
    Row,
    Col,
    Input,
    Form,
    Button,
    message,
    Icon,
    Select,
} from 'antd';
import FormItem from '../../components/FormItem';
import { FormComponentProps, UserInfo, RouteComponentProps, MenuItem } from '../../interface';
import { phone, password, captcha } from '../../utils/rules';
import { manageLogin, manageMenu } from '../../api/api';
import { resCode } from '../../utils/code';
import connectDecorator from '../../connect/Login';
import { withRouter } from 'react-router-dom';
import { asyncRoutes } from '../../router/routes';
import { filterPowerMenu, darkClone } from '../../utils/util';
import Captcha from 'react-captcha-code/build/es';

const { Option } = Select;

interface IProps extends FormComponentProps {
    setUserInfo: (payload: UserInfo | null) => void;
}

interface IProps extends RouteComponentProps {
};

interface State {
    defaultVal: any,
    testAndDev: boolean,
    onLineFlag: boolean,
    errorCount: number,
    captchaRef: any
}

// TODO decorator
class Login extends React.Component<IProps, State> {
    readonly state = {
        defaultVal: '',
        testAndDev: true,
        onLineFlag: false,
        errorCount: 0,
        captchaRef: '',
    };

    //判定环境
    componentDidMount() {
        let es = window.location.hostname;
        if (RegExp(/test/).test(es)) {
            this.setState({ defaultVal: '1', testAndDev: true, onLineFlag: true });
        } else if (RegExp(/dev/).test(es)) {
            this.setState({ defaultVal: '0', testAndDev: false, onLineFlag: true });
        } else if (RegExp(/localhost/).test(es)) {
            this.setState({ onLineFlag: true });
        }
    }

    render() {
        const { form } = this.props;
        const { defaultVal, testAndDev, onLineFlag } = this.state;
        return (
            <>
                <Row style={{
                    textAlign: 'center',
                    paddingTop: '83px',
                }}>
                    <img src={logo} alt="" />
                </Row>
                <Row style={{
                    position: 'relative',
                }}>
                    <img style={{ width: '100%' }} src={bg} alt="" />
                    <Row style={{
                        width: '568px',
                        position: 'absolute',
                        top: '75px',
                        left: '50%',
                        marginLeft: '-284px',
                    }}>
                        <Row style={{
                            minHeight: '360px',
                            background: '#fff',
                            padding: '33px 60px',
                            borderRadius: '4px',
                            boxShadow: '0 4px 16px rgba(0,0,0,.2)',
                        }}>
                            <Row style={{
                                marginBottom: '25px',
                                textAlign: 'center',
                                fontSize: '24px',
                                color: '#009587',
                            }}>
                                <Col style={{
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    width: '50px',
                                    height: '1px',
                                    background: '#009587',
                                }} />
                                <Col style={{
                                    display: 'inline-block',
                                    margin: '0 10px',
                                }}>用户登录</Col>
                                <Col style={{
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    width: '50px',
                                    height: '1px',
                                    background: '#009587',
                                }} />
                            </Row>
                            <Form onSubmit={this.handleSubmit}>
                                {onLineFlag &&
                                    <>
                                        <Select
                                            placeholder="本地环境"
                                            style={{ marginBottom: '20px' }}
                                            onSelect={this.onSel}
                                            value={defaultVal ? defaultVal : undefined}
                                        >
                                            <Option value="0">开发环境</Option>
                                            <Option value="1">测试环境</Option>
                                        </Select>
                                        {testAndDev ?
                                            <Select
                                                placeholder="选择用户级别"
                                                style={{ marginBottom: '20px' }}
                                                onSelect={this.onSelectTestUser}
                                            >
                                                <Option value="10">超管</Option>
                                                <Option value="11">湖北省大客户经理</Option>
                                                <Option value="12">十堰市烟草局管理员账号</Option>
                                                <Option value="13">郧县客户经理</Option>
                                                <Option value="14">郧县银行账号</Option>
                                                <Option value="15">郧县烟草商户</Option>
                                                <Option value="16">武汉市通用管理员账号</Option>
                                                <Option value="17">汉阳区客户经理账号</Option>
                                            </Select>
                                            :
                                            <Select
                                                placeholder="选择用户级别"
                                                style={{ marginBottom: '20px' }}
                                                onSelect={this.onSelectDevUser}
                                            >
                                                <Option value="20">超管账号</Option>
                                                <Option value="21">烟草用户（市级）</Option>
                                                <Option value="22">子管理-大客户经理</Option>
                                            </Select>}
                                    </>}
                                <FormItem field="username" form={form} rules={[
                                    { required: true, trigger: 'blur', message: '此项为必填字段' },
                                    // phone(),
                                ]}>
                                    <Input
                                        size="large"
                                        allowClear={true}
                                        addonBefore={<i className="iconfont iconyonghu" />}
                                        placeholder="请输入手机号" />
                                </FormItem>
                                <FormItem field="password" form={form} rules={[
                                    { required: true, trigger: 'blur', message: '此项为必填字段' },
                                    password(),
                                ]}>
                                    <Input
                                        size="large"
                                        addonBefore={<Icon type="lock" theme="filled" />}
                                        allowClear={true}
                                        type="password"
                                        placeholder="请在此输入密码" />
                                </FormItem>
                                {this.state.errorCount >= 3 ? <FormItem field="captcha" form={form} rules={[
                                    { required: true, trigger: 'blur', message: '此项为必填字段' },
                                    captcha(),
                                ]}>
                                    <Input
                                        className="input-captcha"
                                        size="large"
                                        maxLength={5}
                                        addonBefore={<Icon type="check-square" theme="filled" />}
                                        addonAfter={<Captcha charNum={5} onChange={(e) => this.captchaChange(e, this)}
                                                             onRef={(ref: any) => {
                                                                 this.setState({ captchaRef: ref });
                                                             }} />}
                                        allowClear={true}
                                        placeholder="请在此输入验证码" />
                                </FormItem> : null}
                                <Row style={{
                                    textAlign: 'right',
                                    marginBottom: '10px',
                                }}>
                                </Row>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    style={{
                                        width: '100%',
                                    }}>
                                    登录
                                </Button>
                            </Form>
                        </Row>
                        <Row style={{
                            textAlign: 'center',
                            marginTop: '30px',
                            fontSize: '14px',
                            color: '#898989',
                        }}>
                            武汉精臣智慧标识科技有限公司 · 版权所有 © 2000-2046
                        </Row>
                        <Row style={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: '#898989',
                        }}>
                            Wuhan Jingchen Wisdom Logo Technology All Right reserved
                        </Row>
                        <Row style={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: '#898989',
                            cursor: 'pointer',
                        }} onClick={() => {
                            window.open(' http://www.beian.miit.gov.cn/');
                        }}>
                            鄂ICP备18015935号
                        </Row>
                    </Row>
                </Row>

            </>
        );
    }

    //选择登录环境
    onSel = (e: any) => {
        if (!!Number(e) === !!RegExp(/test/).test(window.location.hostname)) return;
        if (Number(e)) {
            window.location.href = 'http://ycgj.y.jc-test.cn/';
        } else {
            window.location.href = 'http://ycgj.jc-dev.cn/';
        }
    };
    //选择测试环境的登录账号密码
    onSelectTestUser = (e: any) => {
        let itemUser = '';
        let itemPwa = '';
        e = Number(e);
        switch (e) {
            case 10:
                itemUser = '15920575191';
                itemPwa = 'a12345679';
                break;
            case 11:
                itemUser = '18916011104';
                itemPwa = 'a12345678';
                break;
            case 12:
                itemUser = '18916011101';
                itemPwa = 'a12345678';
                break;
            case 13:
                itemUser = '18916011112';
                itemPwa = 'a12345678';
                break;
            case 14:
                itemUser = '18916011115';
                itemPwa = 'a12345678';
                break;
            case 15:
                itemUser = '18916010017';
                itemPwa = 'a12345678';
                break;
            case 16:
                itemUser = '18916016002';
                itemPwa = 'a12345678';
                break;
            case 17:
                itemUser = '18916016003';
                itemPwa = 'a12345678';
                break;
        }
        this.props.form.setFieldsValue({
            username: itemUser,
            password: itemPwa,
        });
    };
    //选择开发环境的登录账号密码
    onSelectDevUser = (e: any) => {
        let itemUser = '';
        let itemPwa = '';
        e = Number(e);
        switch (e) {
            case 20:
                itemUser = '15920575191';
                itemPwa = 'a12345679';
                break;
            case 21:
                itemUser = 'HW5g7gn2';
                itemPwa = 'a12345678';
                break;
            case 22:
                itemUser = 'DQpdwwbd';
                itemPwa = 'a12345678';
                break;
        }
        this.props.form.setFieldsValue({
            username: itemUser,
            password: itemPwa,
        });
    };
    //登录
    handleSubmit = (e: any) => {
        e.preventDefault();
        const { form } = this.props;
        form && form.validateFields((err, values) => {
            if (!err) {
                manageLogin(values)
                    .then((res: any) => {
                        if (res.code === resCode.success) {
                            this.props.setUserInfo(res.data);
                            manageMenu().then((res: any) => {
                                if (res.code === resCode.success) {
                                    let routeArr: any = filterPowerMenu(darkClone(asyncRoutes), res.data);
                                    this.props.history.push('/index');
                                } else {
                                    message.warning(res.message);
                                    this.errorCountChange();
                                }
                            });
                        } else {
                            message.warning(res.message);
                            this.errorCountChange();
                        }
                    });
            } else {
                this.errorCountChange();
            }
        });
    };

    // 登录错误次数变更
    errorCountChange() {
        if (this.state.errorCount < 3) {
            this.setState({ errorCount: this.state.errorCount + 1 });
        } else {
            // 刷新验证码
            if ((this.state.captchaRef as any).current) {
                (this.state.captchaRef as any).current.click();
            }
        }
    }

    // 验证码变更
    captchaChange(e: string, that: any) {
        sessionStorage.setItem('captcha', e);
        that.props.form.setFieldsValue(that.props.form.getFieldsValue());
        that.forceUpdate(() => {
            that.props.form.validateFields();
        });
    }
}

export default withRouter(connectDecorator(Form.create()(Login)));
