import { combineReducers } from 'redux';
import { 
    RoleListAction, 
    RoleListState,
    // UserAuthState,
    // UserAuthAction,
    UserAppAuthState,
    UserAppAuthAction,
} from '../../actions/roleAuthority';
import {
    SET_ROLE_LIST, 
    // SET_USER_AUTH,
    SET_USER_APP_AUTH,
} from '../../constants/roleAuthority';

const roleList = (state: RoleListState = null, action: RoleListAction) => {
    switch (action.type) {
        case SET_ROLE_LIST:
            return action.payload;
        default: 
            return state;
    }
}

// const userAuth = (state: UserAuthState = null, action: UserAuthAction) => {
//     switch (action.type) {
//         case SET_USER_AUTH:
//             return action.payload;
//         default: 
//             return state;
//     }
// }

const userAppAuth = (state: UserAppAuthState = null, action: UserAppAuthAction) => {
    switch (action.type) {
        case SET_USER_APP_AUTH:
            return action.payload;
        default:
            return state;
    }
}

const roleAuthority = combineReducers({
    roleList,
    // userAuth,
    userAppAuth,
});

export default roleAuthority;