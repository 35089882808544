import { all, call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_INFORFIELD, 
    SET_INFORFIELD, 
} from '../../constants/business';
import { 
    getBusinessInfoField,
} from '../../api/api';

function* getInfoField(action: any) {
    const { data } = yield call(getBusinessInfoField, action.payload);
    yield put({ type: SET_INFORFIELD, payload: data });
}
export default function* watchBusiness() {
    yield all([
        takeEvery(GET_INFORFIELD, getInfoField),
    ])
}
