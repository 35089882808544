const href = window.location.href; // 截取URL地址区分是否带项目路径
const cutTag = '/#';
// const baseUrl = 'http://ycgj.y.jc-test.cn/';
const baseUrl = 'https://ycgj.jc-dev.cn/';
// const baseUrl = 'http://localhost:8091/';

export default function setBaseUrl() {
    let apiUrl = '';
    if (process.env.NODE_ENV === 'production') {
        const localHref = href.substring(0, href.indexOf(cutTag));
        apiUrl = baseUrl ? localHref : baseUrl; // 判断是否分离部署
    } else {
        apiUrl = baseUrl; // 判断是否分离部署
    }
    return apiUrl;
}
